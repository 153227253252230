<template>
  <div v-if="show">
    <table
      class="border-0"
      style="
        border-color: transparent;
        border-top: 0 !important ;
        border-width: 0 !important;
        width: 100%;
      "
    >
      <b-tbody class="border-0" role="rowgroup" style="border-width: 0 !important">
        <b-tr class="border-0" style="border-width: 0 !important; border-top: 0">
          <b-td colspan="5" style="font-weight: 600">
            <div style="margin: 0.5rem">
              {{ title }}
            </div>
          </b-td>
          <b-td colspan="3"> </b-td>
          <b-td colspan="2" class="text-right">
            <div style="margin-right: 2rem" v-if="displayDiff">
              <diff-span
                :amount="diffAmount"
                :showSpan="diffAmount != 0"
                :defClass="diffAmount > 0"
                :showArrow="diffAmount > 0"
              />
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </table>
    <div style="background: #f8f8f8">
      <b-table-simple fixed style="margin-bottom: 30px">
        <b-tbody>
          <b-tr
            class="border-group-details"
            v-for="(item, index) in items"
            :key="index + 'A'"
          >
            <b-td colspan="5" class="m-0 p-0">
              <div style="margin: 0.5rem">
                {{ item.name }}
              </div>

              <!-- <p class="text-muted">
                                {{ item.details }}
                              </p> -->
            </b-td>
            <b-td colspan="3"> </b-td>
            <b-td colspan="2">
              {{
                Number(item.total_price / sqft).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </b-td>
          </b-tr>
          <b-tr class="border-group-details" v-if="items.length == 0">
            <b-td colspan="5"> No items included in Base Contract </b-td>
            <b-td colspan="2"> </b-td>
            <b-td colspan="2"> </b-td>
          </b-tr>
          <b-tr class="border-group-details" v-if="openSpecVisible">
            <b-td colspan="5" class="m-0 p-0">
              <div style="margin: 0.5rem">Open Spec Ancillary Cash Allowance</div></b-td
            >
            <b-td colspan="3"> </b-td>
            <b-td colspan="2">{{
                Number(openSpecTotal).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import DiffSpan from "./Utils/DiffSpan.vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCol,
    BButton,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    VBToggle,
    DiffSpan,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
    sqft: {
      type: Number,
      default: 0,
    },
    displayDiff: {
      type: Boolean,
      default: false,
    },
    diffAmount: {
      type: Number,
      default: Number,
    },
    openSpecVisible: {
      type: Boolean,
      default: false,
    },
    openSpecTotal: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.border-group-details {
  border-bottom: 1px solid rgba(31, 41, 47, 0.05);
}
</style>
