<template>
  <section class="invoice-preview-wrapper">
    <!-- <div id="lottie" >
      <div class="loading-logo">
        <img alt="Logo" src="/logo-transparent.png">
      </div>
      <div class="loading" v-show="show">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div> -->
    <b-col
      cols="12"
      style="text-align: center; padding-top: 15%"
      align-self="center"
      v-if="!(quoteAId && quoteBId)"
    >
      <h1>Select Quotes to Compare</h1>
      <p>Select two quotes to start comparing.</p>
      <b-row align-h="center">
        <b-col md="3" align-self="center">
          <b-form-group>
            <label for="InputHelp" class="title-step1" style="padding-top: 21px"
              >Quote 1</label
            >
            <v-select
              v-model="quoteAId"
              label="tfr_name"
              :options="quotes"
              item-value="id"
              item-text="tfr_name"
              :reduce="(quote) => quote.id"
              @input="quoteAChange"
              :selectable="(quote) => quote.id != quoteAId && quote.id != quoteBId"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="3" align-self="center">
          <b-form-group>
            <label for="InputHelp" class="title-step1" style="padding-top: 21px"
              >Quote 2</label
            >
            <v-select
              v-model="quoteBId"
              label="tfr_name"
              :options="quotes"
              item-value="id"
              item-text="tfr_name"
              :reduce="(quote) => quote.id"
              @input="quoteBChange"
              :selectable="(quote) => quote.id != quoteAId && quote.id != quoteBId"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button v-b-modal.modal-duplication variant="primary"> Compare Quotes </b-button>
    </b-col>
    <b-row v-if="quoteAId && quoteBId" class="invoice-preview">
      <b-col cols="6" md="4" xl="6">
        <b-card class="invoice-preview-card" no-body>
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <b-row
              align-h="center"
              style="border-bottom: 1px solid rgba(31, 41, 47, 0.05); margin-bottom: 30px"
            >
              <b-col md="12" align-self="center">
                <b-form-group>
                  <v-select
                    v-model="quoteAId"
                    label="tfr_name"
                    :options="quotes"
                    item-value="id"
                    item-text="tfr_name"
                    :reduce="(quote) => quote.id"
                    @input="quoteAChange2"
                    :selectable="(quote) => quote.id != quoteAId && quote.id != quoteBId"
                  ></v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <h2 style="color:  rgba(59, 171, 255, 1) ">
                  <a
                    target="_blank"
                    :href="`/opportunities/${this.$route.params.opportunityid}/${quoteASelected.id}`"
                    >{{ quoteASelected.tfr_name }}</a
                  >
                </h2>
              </b-col>
              <b-col cols="2">
                <p style="font-weight: 400; color: #b9b9c3">
                  {{ "DC Version " + quoteASelected.base_spec_version }}
                </p>
              </b-col>
            </b-row>
            <b-badge
              pill
              variant="primary"
              style="
                height: 20.4px;
                background-color: rgba(32, 201, 151, 0.12);
                color: #20c997;
              "
              :style="
                !quoteBSelected.marked_as_active == true
                  ? 'margin-right:0'
                  : 'margin-right:25px'
              "
              v-if="quoteASelected.marked_as_active == true"
              >Active</b-badge
            >
            <b-badge
              pill
              variant="primary"
              style="
                height: 20.4px;
                background-color: rgba(32, 201, 151, 0.12);
                color: #20c997;
              "
              v-if="quoteASelected.submitted_for_handover == true"
              >Submitted for Handover</b-badge
            >
            <h4 style="color: #6e6b7b; padding-top: 13px">
              {{ quoteASelected.address }}
            </h4>
            <p style="color: #b9b9c3; padding-top: 8px">
              Last Updated {{ quoteASelected.last_updated }}
            </p>
            <p style="padding-right: 20px; padding-left: 5px">
              <uil-ruler class="logo" size="15px" />
              {{
                Number(quoteASelected.sqft).toLocaleString("en", {
                  minimumFractionDigits: 0,
                }) + " sqft"
              }}
            </p>
            <b-row style="margin-bottom: 25px">
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Project $/sqft</label
                >
                <h2
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{
                    Number(quoteASelected.project_dollar_per_sqft).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </h2>
              </b-col>
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Project Total</label
                >
                <h2
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{
                    Number(quoteASelected.project_total).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </h2>
              </b-col>
            </b-row>
            <b-row
              v-if="
                areWithinTheSameSpec &&
                quoteASelected.base_spec_version < 3 &&
                quoteBSelected.base_spec_version < 3
              "
              style="margin-bottom: 50px; height: 80px"
            >
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Occupancy</label
                >
                <h2
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{ quoteASelected.density_occupancy }} persons
                </h2>
              </b-col>
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Density</label
                >
                <h2
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{
                    quoteASelected.density_occupancy != 0
                      ? Math.ceil(quoteASelected.sqft / quoteASelected.density_occupancy)
                      : 0
                  }}
                  sqft/person
                </h2>
              </b-col>
            </b-row>
            <app-collapse>
              <app-collapse-item
                ref="constructionA"
                :title="
                  quoteASelected.base_spec_version < 3
                    ? 'Construction'
                    : 'Hard Construction'
                "
                :hasChanged="false"
                :diffClassProp="
                  quoteBSelected.construction_dollar_per_sqft >
                    quoteASelected.construction_dollar_per_sqft || !areWithinTheSameSpec
                    ? 'increase'
                    : 'decrease'
                "
                :amount="Number(quoteASelected.construction_dollar_per_sqft * 1)"
                :difference="0"
                :amountClassProp="
                  quoteBSelected.construction_dollar_per_sqft ==
                    quoteASelected.construction_dollar_per_sqft || !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  quoteBSelected.construction_dollar_per_sqft ==
                  quoteASelected.construction_dollar_per_sqft
                    ? false
                    : areWithinTheSameSpec
                "
              >
                <b-col cols="12" v-if="areWithinTheSameSpec">
                  <b-table-simple>
                    <b-tbody
                      v-if="
                        quoteBSelected.ctr_base_spec_rolled_items !=
                          quoteASelected.ctr_base_spec_rolled_items &&
                        quoteASelected.base_spec_version >= 3
                      "
                    >
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div style="background: #f8f8f8">
                    <b-table-simple fixed style="margin-bottom: 30px">
                      <b-tbody>
                        <b-tr class="border-group-details">
                          <template
                            v-if="
                              quoteBSelected.construction_profit_dollar_per_sqft !==
                              quoteASelected.construction_profit_dollar_per_sqft
                            "
                          >
                            <b-td colspan="5"> Profit </b-td>
                            <b-td colspan="3"> </b-td>
                            <b-td colspan="2">
                              {{
                                "$" +
                                Number(
                                  quoteASelected.construction_profit_dollar_per_sqft
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              }}
                              /sqft
                            </b-td>
                          </template>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <template
                      v-for="name in Number(quoteASelected.base_spec_version) >= 3
                        ? sortingArrv2
                        : sortingArr"
                    >
                      <b-table-simple
                        fixed
                        style="margin-bottom: 30px"
                        :key="name + 'A'"
                        v-if="showCategoryTable(name)"
                      >
                        <b-thead>
                          <b-tr>
                            <b-th colspan="5" style="color: #b9b9c3 !important">
                              {{ name }}
                            </b-th>
                            <b-th colspan="3"> </b-th>
                            <b-th colspan="2"> </b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <template
                            v-for="(construction_specs, index) in quoteASelected
                              .construction_breakdown[name]"
                          >
                            <template
                              v-for="(construction_spec, value) in construction_specs"
                            >
                              <b-tr
                                class="border-group-details a"
                                :key="construction_spec.id"
                                v-if="
                                  (quoteASelected.construction_breakdown[name] &&
                                    quoteASelected.construction_breakdown[name][index] &&
                                    quoteASelected.construction_breakdown[name][index][
                                      value
                                    ] &&
                                    quoteASelected.construction_breakdown[name][index][
                                      value
                                    ]['is_contingency']) ||
                                  name == 'General Requirements' ||
                                  (quoteASelected.construction_breakdown[name] &&
                                    quoteASelected.construction_breakdown[name][index] &&
                                    quoteASelected.construction_breakdown[name][index][
                                      value
                                    ] &&
                                    hasBaseSpecAdjustment(
                                      quoteASelected.construction_breakdown[name][index][
                                        value
                                      ]['construction_work_id']
                                    ))
                                    ? quoteBSelected.construction_breakdown[name] &&
                                      quoteBSelected.construction_breakdown[name][
                                        index
                                      ] &&
                                      quoteBSelected.construction_breakdown[name][index][
                                        value
                                      ] &&
                                      quoteBSelected.construction_breakdown[name][index][
                                        value
                                      ]['total_price'] +
                                        constructionAdjustmentTotalPriceB(
                                          quoteBSelected.construction_breakdown[name][
                                            index
                                          ][value]['construction_work_id']
                                        ) !==
                                        (quoteASelected.construction_breakdown[name][
                                          index
                                        ] &&
                                          quoteASelected.construction_breakdown[name][
                                            index
                                          ][value] &&
                                          quoteASelected.construction_breakdown[name][
                                            index
                                          ][value]['total_price'] +
                                            constructionAdjustmentTotalPrice(
                                              quoteASelected.construction_breakdown[name][
                                                index
                                              ][value]['construction_work_id']
                                            ))
                                    : (quoteBSelected.construction_breakdown[name] &&
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ] &&
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value] &&
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft']) !==
                                      (quoteASelected.construction_breakdown[name][
                                        index
                                      ] &&
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value] &&
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft'])
                                "
                              >
                                <b-td colspan="5">
                                  <div
                                    v-if="
                                      hasBaseSpecAdjustment(
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['construction_work_id']
                                      )
                                    "
                                    class="d-flex"
                                  >
                                    <div
                                      v-for="construction_adjustment in constructionAdjustments(
                                        construction_spec.construction_work_id
                                      )"
                                      :key="construction_adjustment + 'row_adjusted_name'"
                                    >
                                      <div v-if="construction_adjustment.name != ''">
                                        {{ construction_adjustment.name }}
                                      </div>
                                      <div v-else>{{ construction_spec.name }}</div>
                                    </div>
                                  </div>
                                  <div v-else>
                                    {{ construction_spec.name }}
                                  </div>
                                </b-td>
                                <b-td
                                  colspan="3"
                                  v-if="
                                    construction_spec.name == 'Construction Management'
                                  "
                                >
                                </b-td>
                                <b-td colspan="3" v-else>
                                  {{
                                    (
                                      construction_spec.qty +
                                      constructionAdjustmentTotalQuantity(
                                        construction_spec.construction_work_id
                                      )
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 2,
                                    }) +
                                    " " +
                                    construction_spec.unit_type
                                  }}
                                </b-td>
                                <b-td colspan="2">
                                  {{
                                    Number(
                                      quoteASelected.construction_breakdown[name][index][
                                        value
                                      ]["is_contingency"] ||
                                        name == "General Requirements" ||
                                        hasBaseSpecAdjustment(
                                          quoteASelected.construction_breakdown[name][
                                            index
                                          ][value]["construction_work_id"]
                                        )
                                        ? (construction_spec.total_price +
                                            constructionAdjustmentTotalPrice(
                                              quoteASelected.construction_breakdown[name][
                                                index
                                              ][value]["construction_work_id"]
                                            )) /
                                            quoteASelected.sqft
                                        : construction_spec.cost_per_sqft
                                    ).toLocaleString("en-CA", {
                                      minimumFractionDigits: 2,
                                      style: "currency",
                                      currency: "CAD",
                                    })
                                  }}
                                </b-td>
                              </b-tr>
                              <!-- <b-tr
                                class="border-group-details a"
                                :key="construction_spec.id"
                                v-if="!quoteBSelected.construction_breakdown[name][index]"
                              >
                                <b-td colspan="5">
                                  {{ construction_spec.name }}
                                </b-td>
                                <b-td
                                  colspan="3"
                                  v-if="
                                    construction_spec.name == 'Construction Management'
                                  "
                                >
                                </b-td>
                                <b-td colspan="3" v-else>
                                  {{
                                    construction_spec.qty.toLocaleString("en", {
                                      minimumFractionDigits: 2,
                                    }) +
                                    " " +
                                    construction_spec.unit_type
                                  }}
                                </b-td>
                                <b-td colspan="2">
                                  {{
                                    Number(
                                      construction_spec.cost_per_sqft
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 2,
                                    })
                                  }}
                                </b-td>
                              </b-tr> -->
                            </template>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </template>
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        (quoteBSelected.cm_fee_enabled ||
                          quoteASelected.cm_fee_enabled) &&
                        quoteBSelected.cm_fee != quoteASelected.cm_fee
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5"> CM Fee </b-th>
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2"> </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr class="border-group-details">
                          <b-td colspan="5"> CM fee </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              Number(
                                quoteASelected.cm_fee / quoteASelected.sqft
                              ).toLocaleString("en-CA", {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              })
                            }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.items_included_turnkey_dollar_per_sqft !==
                        quoteASelected.items_included_turnkey_dollar_per_sqft
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "items INCLUDED in turnkey"
                                : "items INCLUDED in Base Contract"
                            }}
                          </b-th>
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2"> </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          class="border-group-details"
                          v-for="(
                            includedInTurnkey, index
                          ) in quoteAConstructionTurnkeyIncluded"
                          :key="index + 'A'"
                        >
                          <b-td colspan="5">
                            {{ includedInTurnkey.name }}
                          </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              Number(includedInTurnkey.price_per_sqft).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                              )
                            }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          class="border-group-details"
                          v-if="quoteAConstructionTurnkeyIncluded.length == 0"
                        >
                          <b-td colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "No items included in turnkey "
                                : "No items included in Base Contract"
                            }}</b-td
                          >
                          <b-td colspan="2"> </b-td>
                          <b-td colspan="2"> </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="ctrItems.length > 0 && quoteASelected.base_spec_version >= 3"
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="ctrItems"
                      :sqft="quoteASelected.sqft"
                    />
                    <additions-cash-allowance
                      :show="
                        allowCstrnItems.length > 0 &&
                        quoteASelected.base_spec_version >= 3
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowCstrnItems"
                      :sqft="quoteASelected.sqft"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="ffeA"
                :title="
                  quoteASelected.base_spec_version < 3
                    ? 'Furniture & Fixtures'
                    : 'Furniture'
                "
                :hasChanged="false"
                :diffClassProp="
                  quoteBSelected.ffe_dollar_per_sqft >
                    quoteASelected.ffe_dollar_per_sqft && !areWithinTheSameSpec
                    ? 'increase'
                    : 'decrease'
                "
                :amount="
                  quoteASelected.ffe_toggle
                    ? quoteASelected.ffe_dollar_per_sqft +
                      ffeSubtotal / quoteASelected.sqft
                    : quoteASelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotal / quoteASelected.sqft +
                      allowFfeSubtotal / quoteASelected.sqft
                "
                :difference="0"
                :amountClassProp="
                  quoteBSelected.ffe_dollar_per_sqft +
                    ffeSubtotalB / quoteBSelected.sqft ==
                    quoteASelected.ffe_dollar_per_sqft +
                      ffeSubtotal / quoteASelected.sqft ||
                  (quoteBSelected.ffe_toggle == false &&
                    quoteASelected.ffe_toggle == false) ||
                  !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  (isQuoteAFfeCollapsable ||
                    ffeItems.length > 0 ||
                    allowFfeItems.length > 0 ||
                    quoteBSelected.ffe_base_spec_rolled_items !==
                      quoteASelected.ffe_base_spec_rolled_items) &&
                  areWithinTheSameSpec
                "
                :budgetEnabled="
                  quoteASelected.ffe_toggle ||
                  quoteASelected.ffe_included_turnkey_dollar_per_sqft > 0
                "
              >
                <b-col cols="12">
                  <b-table-simple
                    v-if="
                      quoteBSelected.ffe_base_spec_rolled_items !==
                        quoteASelected.ffe_base_spec_rolled_items &&
                      quoteASelected.ffe_toggle
                    "
                  >
                    <b-tbody v-if="quoteASelected.base_spec_version >= 3">
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div style="background: #f8f8f8">
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.ffe_dollar_per_sqft !==
                          quoteASelected.ffe_dollar_per_sqft && quoteASelected.ffe_toggle
                      "
                    >
                      <b-tbody>
                        <template v-for="(value, name) in quoteASelected.ffe_breakdown">
                          <template v-for="(ffeItem, index) in value">
                            <b-tr
                              class="border-group-details"
                              :key="ffeItem.id + 'A' + ffeItem.id"
                              v-if="
                                quoteBSelected.ffe_breakdown[name] &&
                                quoteASelected.ffe_breakdown[name][index][
                                  'cost_per_sqft'
                                ] !=
                                  quoteBSelected.ffe_breakdown[name][index][
                                    'cost_per_sqft'
                                  ]
                              "
                            >
                              <b-td colspan="5">
                                {{ ffeItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteASelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !ffeItem.specification_swap
                                      ? ffeItem.element.specification_key
                                      : ffeItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ ffeItem.qty }}
                              </b-td>
                              <b-td colspan="2">
                                <div v-if="ffeItem.specification_swap == null">
                                  {{
                                    "$" +
                                    Number(
                                      ffeItem.total_price / quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                                <div v-else>
                                  {{
                                    "$" +
                                    Number(
                                      (ffeItem.specification_swap.total_price * 1 +
                                        ffeItem.specification_swap
                                          .total_delivery_install *
                                          1) /
                                        quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                        <template v-for="(value, name) in quoteASelected.ffe_breakdown">
                          <template v-for="ffeItem in value">
                            <b-tr
                              class="border-group-details"
                              :key="ffeItem.id + 'A'"
                              v-if="!quoteBSelected.ffe_breakdown[name]"
                            >
                              <b-td colspan="5">
                                {{ ffeItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteASelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !ffeItem.specification_swap
                                      ? ffeItem.element.specification_key
                                      : ffeItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ ffeItem.qty }}
                              </b-td>
                              <b-td colspan="2">
                                <div v-if="ffeItem.specification_swap == null">
                                  {{
                                    "$" +
                                    Number(
                                      ffeItem.total_price / quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                                <div v-else>
                                  {{
                                    "$" +
                                    Number(
                                      (ffeItem.specification_swap.total_price * 1 +
                                        ffeItem.specification_swap
                                          .total_delivery_install *
                                          1) /
                                        quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                      </b-tbody>
                    </b-table-simple>

                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.ffe_included_turnkey_dollar_per_sqft !==
                        quoteASelected.ffe_included_turnkey_dollar_per_sqft
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5">
                            {{
                              quoteASelected.base_spec_version < 3
                                ? "items INCLUDED in turnkey"
                                : "items INCLUDED in Base Contract"
                            }}
                          </b-th>
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2"> </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          class="border-group-details"
                          v-for="(includedInTurnkey, index) in quoteAffeTurnkeyIncluded"
                          :key="index + 'A'"
                        >
                          <b-td colspan="5">
                            {{ includedInTurnkey.name }}
                          </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              Number(includedInTurnkey.price_per_sqft).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                              )
                            }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          class="border-group-details"
                          v-if="quoteAffeTurnkeyIncluded.length == 0"
                        >
                          <b-td colspan="5">
                            {{
                              quoteASelected.base_spec_version < 3
                                ? "No items included in turnkey "
                                : "No items included in Base Contract"
                            }}
                          </b-td>
                          <b-td colspan="2"> </b-td>
                          <b-td colspan="2"> </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="ffeItems.length > 0 && quoteASelected.base_spec_version >= 3"
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="ffeItems"
                      :sqft="quoteASelected.sqft"
                    />
                    <additions-cash-allowance
                      :show="
                        allowFfeItems.length > 0 && quoteASelected.base_spec_version >= 3
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowFfeItems"
                      :sqft="quoteASelected.sqft"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="avitA"
                :title="
                  quoteASelected.base_spec_version < 3
                    ? 'AV / IT'
                    : 'Audio Visual & Networking'
                "
                :hasChanged="false"
                :diffClassProp="
                  quoteBSelected.avit_dollar_per_sqft >
                  quoteASelected.avit_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="
                  quoteASelected.avit_toggle
                    ? quoteASelected.avit_dollar_per_sqft +
                      avitSubtotal / quoteASelected.sqft
                    : quoteASelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotal / quoteASelected.sqft +
                      allowAvISubtotal / quoteASelected.sqft
                "
                :difference="0"
                :amountClassProp="
                  quoteBSelected.avit_dollar_per_sqft +
                    avitSubtotalB / quoteBSelected.sqft ==
                    quoteASelected.avit_dollar_per_sqft +
                      avitSubtotal / quoteASelected.sqft ||
                  (quoteBSelected.ffe_toggle == false &&
                    quoteASelected.ffe_toggle == false) ||
                  !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  (isQuoteAAvitCollapsable ||
                    avitItems.length > 0 ||
                    allowAvItItems.length ||
                    quoteBSelected.avit_base_spec_rolled_items !=
                      quoteASelected.avit_base_spec_rolled_items) &&
                  areWithinTheSameSpec
                "
                :budgetEnabled="
                  quoteASelected.avit_toggle ||
                  quoteASelected.avit_included_turnkey_dollar_per_sqft > 0
                "
              >
                <b-col cols="12">
                  <b-table-simple
                    v-if="
                      quoteBSelected.avit_toggle &&
                      quoteBSelected.base_spec_version >= 3 &&
                      quoteBSelected.avit_base_spec_rolled_items !=
                        quoteASelected.avit_base_spec_rolled_items
                    "
                  >
                    <b-tbody
                      v-if="
                        quoteASelected.base_spec_version >= 3 &&
                        quoteBSelected.avit_dollar_per_sqft !==
                          quoteASelected.avit_dollar_per_sqft &&
                        quoteASelected.avit_toggle
                      "
                    >
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div style="background: #f8f8f8">
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.avit_dollar_per_sqft !==
                          quoteASelected.avit_dollar_per_sqft &&
                        quoteASelected.avit_toggle
                      "
                    >
                      <b-tbody>
                        <template v-for="(value, name) in quoteASelected.avit_breakdown">
                          <template v-for="(avitItem, index) in value">
                            <b-tr
                              class="border-group-details"
                              :key="avitItem.id + 'A'"
                              v-if="
                                quoteBSelected.avit_breakdown[name] &&
                                quoteASelected.avit_breakdown[name][index][
                                  'cost_per_sqft'
                                ] !=
                                  quoteBSelected.avit_breakdown[name][index][
                                    'cost_per_sqft'
                                  ]
                              "
                            >
                              <b-td colspan="5">
                                {{ avitItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteASelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !avitItem.specification_swap
                                      ? avitItem.element.specification_key
                                      : avitItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ avitItem.qty }}
                              </b-td>
                              <b-td colspan="2">
                                <div v-if="avitItem.specification_swap == null">
                                  {{
                                    "$" +
                                    Number(
                                      avitItem.total_price / quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                                <div v-else>
                                  {{
                                    "$" +
                                    Number(
                                      (avitItem.specification_swap.total_price * 1 +
                                        avitItem.specification_swap
                                          .total_delivery_install *
                                          1) /
                                        quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                        <template v-for="(value, name) in quoteASelected.avit_breakdown">
                          <template v-for="avitItem in value">
                            <b-tr
                              class="border-group-details"
                              :key="avitItem.key + 'A'"
                              v-if="!quoteBSelected.avit_breakdown[name]"
                            >
                              <b-td colspan="5">
                                {{ avitItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteASelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !avitItem.specification_swap
                                      ? avitItem.element.specification_key
                                      : avitItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ avitItem.qty }}
                              </b-td>
                              <b-td colspan="2">
                                <div v-if="avitItem.specification_swap == null">
                                  {{
                                    "$" +
                                    Number(
                                      avitItem.total_price / quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                                <div v-else>
                                  {{
                                    "$" +
                                    Number(
                                      (avitItem.specification_swap.total_price * 1 +
                                        avitItem.specification_swap
                                          .total_delivery_install *
                                          1) /
                                        quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </div>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                      </b-tbody>
                    </b-table-simple>

                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.avit_included_turnkey_dollar_per_sqft !==
                          quoteASelected.avit_included_turnkey_dollar_per_sqft &&
                        quoteASelected.base_spec_version < 3
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5">
                            {{
                              quoteASelected.base_spec_version < 3
                                ? "items INCLUDED in turnkey"
                                : "items INCLUDED in Base Contract"
                            }}</b-th
                          >
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2"> </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          class="border-group-details"
                          v-for="(includedInTurnkey, index) in quoteAAvItTurnkeyIncluded"
                          :key="index + 'A'"
                        >
                          <b-td colspan="5">
                            {{ includedInTurnkey.name }}
                          </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              Number(includedInTurnkey.price_per_sqft).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                }
                              )
                            }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          class="border-group-details"
                          v-if="quoteAAvItTurnkeyIncluded.length == 0"
                        >
                          <b-td colspan="5">{{
                            quoteASelected.base_spec_version < 3
                              ? "No items included in turnkey "
                              : "No items included in Base Contract"
                          }}</b-td>
                          <b-td colspan="2"> </b-td>
                          <b-td colspan="2"> </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="
                        avitItems.length > 0 && quoteASelected.base_spec_version >= 3
                      "
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="avitItems"
                      :sqft="quoteASelected.sqft"
                    />
                    <additions-cash-allowance
                      :show="
                        allowAvItItems.length > 0 && quoteASelected.base_spec_version >= 3
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowAvItItems"
                      :sqft="quoteASelected.sqft"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="siteConditionsA"
                :title="'Site Conditions'"
                :hasChanged="false"
                :diffClassProp="
                  quoteBSelected.site_conditions_dollar_per_sqft >
                  quoteASelected.site_conditions_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="quoteASelected.site_conditions_dollar_per_sqft"
                :difference="0"
                :amountClassProp="
                  quoteBSelected.site_conditions_dollar_per_sqft ==
                    quoteASelected.site_conditions_dollar_per_sqft ||
                  !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  quoteBSelected.site_conditions_dollar_per_sqft ==
                    quoteASelected.site_conditions_dollar_per_sqft ||
                  !areWithinTheSameSpec
                    ? false
                    : true
                "
                v-if="quoteASelected.base_spec_version < 3"
              >
                <b-col cols="12" style="background: #f8f8f8">
                  <b-table-simple
                    fixed
                    v-if="
                      quoteBSelected.site_conditions_dollar_per_sqft !=
                      quoteASelected.site_conditions_dollar_per_sqft
                    "
                  >
                    <b-tbody>
                      <b-tr
                        class="border-group-details"
                        v-for="(siteConditions, index) in quoteASiteConditions"
                        :key="index + 'B'"
                      >
                        <b-td colspan="5">
                          {{ siteConditions.name }}
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2">
                          {{[(siteConditions.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(siteConditions.price_per_sqft)).toLocaleString('en', { minimumFractionDigits: 2 }) + ' /sqft'}}
                        </b-td>
                      </b-tr>
                      <b-tr
                        class="border-group-details"
                        v-if="quoteASiteConditions.length == 0"
                      >
                        <b-td colspan="5"> No Site Conditions </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="optionsA"
                :title="'Additional Scope & Upgrades'"
                :hasChanged="false"
                :diffClassProp="
                  quoteBSelected.options_dollar_per_sqft >
                  quoteASelected.options_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="quoteASelected.options_dollar_per_sqft"
                :difference="0"
                :amountClassProp="
                  quoteBSelected.options_dollar_per_sqft ==
                    quoteASelected.options_dollar_per_sqft || !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  quoteBSelected.options_dollar_per_sqft ==
                    quoteASelected.options_dollar_per_sqft || !areWithinTheSameSpec
                    ? false
                    : true
                "
                v-if="quoteASelected.base_spec_version < 3"
              >
                <b-col cols="12" style="background: #f8f8f8">
                  <b-table-simple
                    fixed
                    v-if="
                      quoteBSelected.options_dollar_per_sqft !=
                      quoteASelected.options_dollar_per_sqft
                    "
                  >
                    <b-tbody>
                      <b-tr
                        class="border-group-details"
                        v-for="(options, index) in quoteAOptions"
                        :key="index + 'B'"
                      >
                        <b-td colspan="5">
                          {{ options.name }}
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2">
                          {{[(options.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(options.price_per_sqft)).toLocaleString('en', { minimumFractionDigits: 2 }) + ' /sqft'}}
                        </b-td>
                      </b-tr>
                      <b-tr class="border-group-details" v-if="quoteAOptions.length == 0">
                        <b-td colspan="5">
                          No Additional Scope & Upgrades for this quote
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                :title="'Clearspace Fee'"
                :hasChanged="false"
                :diffClassProp="
                  quoteBSelected.clearspace_fee_dollar_per_sqft >
                  quoteASelected.clearspace_fee_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="quoteASelected.clearspace_fee_dollar_per_sqft"
                :difference="0"
                :amountClassProp="
                  quoteBSelected.clearspace_fee_dollar_per_sqft ==
                  quoteASelected.clearspace_fee_dollar_per_sqft
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="false"
                v-if="quoteASelected.base_spec_version < 3"
              >
              </app-collapse-item>
              <app-collapse-item
                ref="softCostA"
                :title="'Soft Costs'"
                :hasChanged="false"
                :diffClassProp="ScGlobalTotalB > ScGlobalTotal ? 'increase' : 'decrease'"
                :amount="ScGlobalTotal"
                :difference="0"
                :amountClassProp="ScGlobalTotal == ScGlobalTotalB ? 'muted' : 'different'"
                :collapsableItem="
                  ScGlobalTotal != ScGlobalTotalB &&
                  areWithinTheSameSpec &&
                  quoteBSelected.sc_base_spec_rolled_items !=
                    quoteASelected.sc_base_spec_rolled_items
                "
                v-if="quoteASelected.base_spec_version >= 3"
              >
                <b-col cols="12">
                  <b-table-simple
                    v-if="
                      quoteBSelected.sc_base_spec_rolled_items !=
                      quoteASelected.sc_base_spec_rolled_items
                    "
                  >
                    <b-tbody>
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <!--  v-if="ScGlobalTotal !== ScGlobalTotalB" -->
                  <div
                    style="background: #f8f8f8"
                    v-if="
                      quoteBSelected.sc_base_spec_rolled_items !=
                      quoteASelected.sc_base_spec_rolled_items
                    "
                  >
                    <b-table-simple fixed style="margin-bottom: 30px">
                      <b-tbody>
                        <template>
                          <!-- v-if="
                                quoteASelected.construction_pm_buffer * 1 !=
                                quoteBSelected.construction_pm_buffer * 1
                              " -->
                          <template>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.design_ame_buffer !=
                                quoteASelected.design_ame_buffer
                              "
                            >
                              <b-td colspan="5"> Design + Architectural </b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteASelected.design_ame_buffer * 1
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.construction_pm_buffer !=
                                quoteASelected.construction_pm_buffer
                              "
                            >
                              <b-td colspan="5"> Project Management </b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteASelected.construction_pm_buffer * 1
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.engineering_fee_price !=
                                quoteASelected.engineering_fee_price
                              "
                            >
                              <b-td colspan="5"> Engineering Fee </b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteASelected.engineering_fee_price * 1
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.permit_fees / quoteBSelected.sqft !=
                                quoteASelected.permit_fees / quoteASelected.sqft
                              "
                            >
                              <b-td colspan="5"> Permits</b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteASelected.permit_fees / quoteASelected.sqft
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="scItems.length > 0 && quoteASelected.base_spec_version >= 3"
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="scItems"
                      :sqft="quoteASelected.sqft"
                    />
                    <additions-cash-allowance
                      :show="
                        allowSCItems.length > 0 && quoteASelected.base_spec_version >= 3
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowSCItems"
                      :sqft="quoteASelected.sqft"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
            </app-collapse>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="6" md="4" xl="6">
        <b-card class="invoice-preview-card" no-body>
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <b-row
              align-h="center"
              style="border-bottom: 1px solid rgba(31, 41, 47, 0.05); margin-bottom: 30px"
            >
              <b-col md="12" align-self="center">
                <b-form-group>
                  <v-select
                    v-model="quoteBId"
                    label="tfr_name"
                    :options="quotes"
                    item-value="id"
                    item-text="tfr_name"
                    :reduce="(quote) => quote.id"
                    @input="quoteBChange2"
                    :selectable="(quote) => quote.id != quoteAId && quote.id != quoteBId"
                  ></v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <h2 style="color:  rgba(59, 171, 255, 1) ">
                  <a
                    target="_blank"
                    :href="`/opportunities/${this.$route.params.opportunityid}/${quoteBSelected.id}`"
                    >{{ quoteBSelected.tfr_name }}</a
                  >
                </h2>
              </b-col>
              <b-col cols="2">
                <p
                  style="font-weight: 400"
                  :class="
                    quoteBSelected.base_spec_version != quoteASelected.base_spec_version
                      ? 'decrease'
                      : 'baseSpecVer'
                  "
                >
                  {{ "DC Version " + quoteBSelected.base_spec_version }}
                </p>
              </b-col>
            </b-row>
            <b-badge
              pill
              variant="primary"
              style="
                height: 20.4px;
                background-color: rgba(32, 201, 151, 0.12);
                color: #20c997;
              "
              :style="
                !quoteBSelected.marked_as_active == true
                  ? 'margin-right:0'
                  : 'margin-right:25px'
              "
              v-if="quoteBSelected.marked_as_active == true"
              >Active</b-badge
            >
            <b-badge
              pill
              variant="primary"
              style="
                height: 20.4px;
                background-color: rgba(32, 201, 151, 0.12);
                color: #20c997;
              "
              v-if="quoteBSelected.submitted_for_handover == true"
              >Submitted for Handover</b-badge
            >
            <h4 style="color: #6e6b7b; padding-top: 13px">
              {{ quoteBSelected.address }}
            </h4>
            <p style="color: #b9b9c3; padding-top: 8px">
              Last Updated {{ quoteBSelected.last_updated }}
            </p>
            <p style="padding-right: 20px; padding-left: 5px">
              <uil-ruler class="logo" size="15px" />
              {{
                Number(quoteBSelected.sqft).toLocaleString("en", {
                  minimumFractionDigits: 0,
                }) + " sqft"
              }}
              <span
                :class="
                  quoteBSelected.sqft > quoteASelected.sqft ? 'increase' : 'decrease'
                "
                v-if="quoteASelected.sqft != quoteBSelected.sqft"
              >
                <uil-arrow-up v-if="quoteBSelected.sqft > quoteASelected.sqft" />
                <uil-arrow-down v-else />{{
                  Math.abs(quoteBSelected.sqft - quoteASelected.sqft)
                }}
              </span>
            </p>
            <b-row style="margin-bottom: 5px">
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Project $/sqft</label
                >
                <h2
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{
                    Number(quoteBSelected.project_dollar_per_sqft).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </h2>
                <diff-span
                  :defClass="
                    quoteBSelected.project_dollar_per_sqft >
                    quoteASelected.project_dollar_per_sqft
                  "
                  :showSpan="
                    quoteASelected.project_dollar_per_sqft !=
                    quoteBSelected.project_dollar_per_sqft
                  "
                  :showArrow="
                    quoteBSelected.project_dollar_per_sqft >
                    quoteASelected.project_dollar_per_sqft
                  "
                  :amount="
                    quoteBSelected.project_dollar_per_sqft -
                    quoteASelected.project_dollar_per_sqft
                  "
                />
              </b-col>
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Project Total</label
                >
                <h2
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{
                    Number(quoteBSelected.project_total).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </h2>
                <diff-span
                  :defClass="quoteBSelected.project_total > quoteASelected.project_total"
                  :showSpan="quoteASelected.project_total != quoteBSelected.project_total"
                  :showArrow="quoteBSelected.project_total > quoteASelected.project_total"
                  :amount="
                    quoteBSelected.project_total * 1 - quoteASelected.project_total * 1
                  "
                />
              </b-col>
            </b-row>
            <b-row
              v-if="
                areWithinTheSameSpec &&
                quoteASelected.base_spec_version < 3 &&
                quoteBSelected.base_spec_version < 3
              "
              style="margin-bottom: 50px; height: 80px"
            >
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Occupancy</label
                >
                <p
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{ quoteBSelected.density_occupancy }} persons
                  <span
                    :class="
                      quoteBSelected.density_occupancy > quoteASelected.density_occupancy
                        ? 'increase'
                        : 'decrease'
                    "
                    v-if="
                      quoteASelected.density_occupancy != quoteBSelected.density_occupancy
                    "
                    style="
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px !important;
                      line-height: 18px;
                    "
                  >
                    <uil-arrow-up
                      v-if="
                        quoteBSelected.density_occupancy >
                        quoteASelected.density_occupancy
                      "
                    />
                    <uil-arrow-down v-else />{{
                      Number(
                        Math.abs(
                          quoteBSelected.density_occupancy * 1 -
                            quoteASelected.density_occupancy * 1
                        )
                      ).toLocaleString("en", { minimumFractionDigits: 0 })
                    }}
                  </span>
                </p>
              </b-col>
              <b-col md="6" align-self="center">
                <label
                  style="
                    font-weight: 600 !important;
                    font-size: 12px;
                    line-height: 23px;
                    color: #b9b9c3;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                  "
                  >Density</label
                >
                <p
                  style="
                    color: #6e6b7b;
                    font-weight: 500 !important;
                    font-size: 24px;
                    line-height: 24px;
                  "
                >
                  {{
                    quoteBSelected.density_occupancy != 0
                      ? Math.ceil(quoteBSelected.sqft / quoteBSelected.density_occupancy)
                      : 0
                  }}
                  sqft/person
                  <span
                    :class="
                      (quoteBSelected.density_occupancy != 0
                        ? Math.ceil(
                            quoteBSelected.sqft / quoteBSelected.density_occupancy
                          )
                        : 0) >
                      (quoteASelected.density_occupancy != 0
                        ? Math.ceil(
                            quoteASelected.sqft / quoteASelected.density_occupancy
                          )
                        : 0)
                        ? 'increase'
                        : 'decrease'
                    "
                    v-if="
                      (quoteASelected.density_occupancy != 0
                        ? Math.ceil(
                            quoteASelected.sqft / quoteASelected.density_occupancy
                          )
                        : 0) !=
                      (quoteBSelected.density_occupancy != 0
                        ? Math.ceil(
                            quoteBSelected.sqft / quoteBSelected.density_occupancy
                          )
                        : 0)
                    "
                    style="
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px !important;
                      line-height: 18px;
                    "
                  >
                    <uil-arrow-up
                      v-if="
                        (quoteBSelected.density_occupancy != 0
                          ? Math.ceil(
                              quoteBSelected.sqft / quoteBSelected.density_occupancy
                            )
                          : 0) >
                        (quoteASelected.density_occupancy != 0
                          ? Math.ceil(
                              quoteASelected.sqft / quoteASelected.density_occupancy
                            )
                          : 0)
                      "
                    />
                    <uil-arrow-down v-else />{{
                      Math.abs(
                        (quoteASelected.density_occupancy != 0
                          ? Math.ceil(
                              quoteASelected.sqft / quoteASelected.density_occupancy
                            )
                          : 0) -
                          (quoteBSelected.density_occupancy != 0
                            ? Math.ceil(
                                quoteBSelected.sqft / quoteBSelected.density_occupancy
                              )
                            : 0)
                      )
                    }}
                  </span>
                </p>
              </b-col>
            </b-row>
            <app-collapse>
              <app-collapse-item
                ref="constructionB"
                :title="
                  quoteBSelected.base_spec_version < 3
                    ? 'Construction'
                    : 'Hard Construction'
                "
                :hasChanged="
                  quoteBSelected.construction_dollar_per_sqft !=
                  quoteASelected.construction_dollar_per_sqft
                "
                :diffClassProp="
                  quoteBSelected.construction_dollar_per_sqft >
                  quoteASelected.construction_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="quoteBSelected.construction_dollar_per_sqft"
                :difference="
                  quoteBSelected.construction_dollar_per_sqft -
                  quoteASelected.construction_dollar_per_sqft
                "
                :amountClassProp="
                  quoteBSelected.construction_dollar_per_sqft ==
                    quoteASelected.construction_dollar_per_sqft || !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  quoteBSelected.construction_dollar_per_sqft ==
                  quoteASelected.construction_dollar_per_sqft
                    ? false
                    : areWithinTheSameSpec
                "
              >
                <b-col cols="12" v-if="areWithinTheSameSpec">
                  <b-table-simple>
                    <b-tbody
                      v-if="
                        quoteBSelected.ctr_base_spec_rolled_items !==
                          quoteASelected.ctr_base_spec_rolled_items &&
                        quoteBSelected.base_spec_version >= 3
                      "
                    >
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2" class="text-right">
                          <diff-span
                            :defClass="
                              quoteBSelected.ctr_base_spec_rolled_items >
                              quoteASelected.ctr_base_spec_rolled_items
                            "
                            :showSpan="
                              quoteBSelected.ctr_base_spec_rolled_items !=
                              quoteASelected.ctr_base_spec_rolled_items
                            "
                            :showArrow="
                              quoteBSelected.ctr_base_spec_rolled_items >
                              quoteASelected.ctr_base_spec_rolled_items
                            "
                            :amount="
                              quoteBSelected.ctr_base_spec_rolled_items -
                              quoteASelected.ctr_base_spec_rolled_items
                            "
                        /></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div style="background: #f8f8f8">
                    <b-table-simple fixed style="margin-bottom: 30px">
                      <b-tbody>
                        <b-tr class="border-group-details">
                          <template
                            v-if="
                              quoteBSelected.construction_profit_dollar_per_sqft !==
                              quoteASelected.construction_profit_dollar_per_sqft
                            "
                          >
                            <b-td colspan="5"> Profit </b-td>
                            <b-td colspan="3"> </b-td>
                            <b-td colspan="2">
                              {{
                                Number(
                                  quoteBSelected.construction_profit_dollar_per_sqft
                                ).toLocaleString("en-CA", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "CAD",
                                })
                              }}
                              /sqft
                              <diff-span
                                :defClass="
                                  quoteBSelected.construction_profit_dollar_per_sqft >
                                  quoteASelected.construction_profit_dollar_per_sqft
                                "
                                :showSpan="
                                  quoteASelected.construction_profit_dollar_per_sqft !=
                                  quoteBSelected.construction_profit_dollar_per_sqft
                                "
                                :showArrow="
                                  quoteBSelected.construction_profit_dollar_per_sqft >
                                  quoteASelected.construction_profit_dollar_per_sqft
                                "
                                :amount="
                                  quoteBSelected.construction_profit_dollar_per_sqft -
                                  quoteASelected.construction_profit_dollar_per_sqft
                                "
                              />
                            </b-td>
                          </template>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <template
                      v-for="name in Number(quoteBSelected.base_spec_version) >= 3
                        ? sortingArrv2
                        : sortingArr"
                    >
                      <b-table-simple
                        fixed
                        style="margin-bottom: 30px"
                        :key="name + 'B'"
                        v-if="showCategoryTable(name)"
                      >
                        <b-thead>
                          <b-tr>
                            <b-th colspan="5" style="color: #b9b9c3 !important">
                              {{ name }}
                            </b-th>
                            <b-th colspan="3"> </b-th>
                            <b-th colspan="2"> </b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <template
                            v-for="(construction_specs, index) in quoteBSelected
                              .construction_breakdown[name]"
                          >
                            <template
                              v-for="(construction_spec, value) in construction_specs"
                            >
                              <template
                                v-if="
                                  quoteASelected.construction_breakdown[name] &&
                                  quoteASelected.construction_breakdown[name][index]
                                "
                              >
                                <b-tr
                                  class="border-group-details"
                                  :key="construction_spec.id"
                                  v-if="
                                    quoteBSelected.construction_breakdown[name][index][
                                      value
                                    ]['is_contingency'] ||
                                    name == 'General Requirements' ||
                                    hasBaseSpecAdjustmentB(
                                      quoteBSelected.construction_breakdown[name][index][
                                        value
                                      ]['construction_work_id']
                                    ) ||
                                    hasBaseSpecAdjustment(
                                      quoteBSelected.construction_breakdown[name][index][
                                        value
                                      ]['construction_work_id']
                                    )
                                      ? quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPriceB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          ) !==
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPrice(
                                            quoteASelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )
                                      : quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft'] !==
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft']
                                  "
                                >
                                  <b-td colspan="5">
                                    <div
                                      v-if="
                                        hasBaseSpecAdjustmentB(
                                          quoteASelected.construction_breakdown[name][
                                            index
                                          ][value]['construction_work_id']
                                        )
                                      "
                                      class="d-flex"
                                    >
                                      <div
                                        v-for="construction_adjustment in constructionAdjustmentsB(
                                          construction_spec.construction_work_id
                                        )"
                                        :key="
                                          construction_adjustment + 'row_adjusted_name_b'
                                        "
                                      >
                                        <div v-if="construction_adjustment.name != ''">
                                          {{ construction_adjustment.name }}
                                        </div>
                                        <div v-else>{{ construction_spec.name }}</div>
                                      </div>
                                    </div>
                                    <div v-else>
                                      {{ construction_spec.name }}
                                    </div>
                                  </b-td>
                                  <b-td
                                    colspan="3"
                                    v-if="
                                      construction_spec.name == 'Construction Management'
                                    "
                                  >
                                  </b-td>
                                  <b-td colspan="3" v-else>
                                    {{
                                      (
                                        construction_spec.qty +
                                        constructionAdjustmentTotalQuantityB(
                                          construction_spec.construction_work_id
                                        )
                                      ).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                      }) +
                                      " " +
                                      construction_spec.unit_type
                                    }}
                                    <span
                                      style="white-space: nowrap"
                                      :class="
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['qty'] +
                                          constructionAdjustmentTotalQuantityB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          ) >
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['qty'] +
                                          constructionAdjustmentTotalQuantity(
                                            quoteASelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )
                                          ? 'increase'
                                          : 'decrease'
                                      "
                                      v-if="
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['qty'] +
                                          constructionAdjustmentTotalQuantityB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          ) !=
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['qty'] +
                                          constructionAdjustmentTotalQuantity(
                                            quoteASelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )
                                      "
                                    >
                                      <uil-arrow-up
                                        v-if="
                                          quoteBSelected.construction_breakdown[name][
                                            index
                                          ][value]['qty'] +
                                            constructionAdjustmentTotalQuantityB(
                                              quoteBSelected.construction_breakdown[name][
                                                index
                                              ][value]['construction_work_id']
                                            ) >
                                          quoteASelected.construction_breakdown[name][
                                            index
                                          ][value]['qty'] +
                                            constructionAdjustmentTotalQuantity(
                                              quoteASelected.construction_breakdown[name][
                                                index
                                              ][value]['construction_work_id']
                                            )
                                        "
                                      />
                                      <uil-arrow-down v-else />{{
                                        Number(
                                          Math.abs(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]["qty"] +
                                              constructionAdjustmentTotalQuantityB(
                                                quoteBSelected.construction_breakdown[
                                                  name
                                                ][index][value]["construction_work_id"]
                                              ) -
                                              (quoteASelected.construction_breakdown[
                                                name
                                              ][index][value]["qty"] +
                                                constructionAdjustmentTotalQuantity(
                                                  quoteASelected.construction_breakdown[
                                                    name
                                                  ][index][value]["construction_work_id"]
                                                ))
                                          )
                                        ).toLocaleString("en", {
                                          minimumFractionDigits: 0,
                                        })
                                      }}
                                    </span>
                                  </b-td>
                                  <b-td colspan="2">
                                    {{
                                      Number(
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]["is_contingency"] ||
                                          name == "General Requirements" ||
                                          hasBaseSpecAdjustmentB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]["construction_work_id"]
                                          )
                                          ? (construction_spec.total_price +
                                              constructionAdjustmentTotalPriceB(
                                                quoteBSelected.construction_breakdown[
                                                  name
                                                ][index][value]["construction_work_id"]
                                              )) /
                                              quoteBSelected.sqft
                                          : construction_spec.cost_per_sqft
                                      ).toLocaleString("en-CA", {
                                        minimumFractionDigits: 2,
                                        style: "currency",
                                        currency: "CAD",
                                      })
                                    }}
                                    <diff-span
                                      v-if="
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['is_contingency'] ||
                                        name == 'General Requirements' ||
                                        hasBaseSpecAdjustmentB(
                                          quoteBSelected.construction_breakdown[name][
                                            index
                                          ][value]['construction_work_id']
                                        ) ||
                                        hasBaseSpecAdjustment(
                                          quoteBSelected.construction_breakdown[name][
                                            index
                                          ][value]['construction_work_id']
                                        )
                                      "
                                      :defClass="
                                        (quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPriceB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteBSelected.sqft >
                                        (quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPrice(
                                            quoteASelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteASelected.sqft
                                      "
                                      :showSpan="
                                        (quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPrice(
                                            quoteASelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteASelected.sqft !=
                                        (quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPriceB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteBSelected.sqft
                                      "
                                      :showArrow="
                                        (quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPriceB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteBSelected.sqft >
                                        (quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPrice(
                                            quoteASelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteASelected.sqft
                                      "
                                      :amount="
                                        (quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPriceB(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteBSelected.sqft -
                                        (quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['total_price'] +
                                          constructionAdjustmentTotalPrice(
                                            quoteASelected.construction_breakdown[name][
                                              index
                                            ][value]['construction_work_id']
                                          )) /
                                          quoteASelected.sqft
                                      "
                                    />
                                    <diff-span
                                      v-else
                                      :defClass="
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft'] >
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft']
                                      "
                                      :showSpan="
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft'] !=
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft']
                                      "
                                      :showArrow="
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft'] >
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft']
                                      "
                                      :amount="
                                        quoteBSelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft'] -
                                        quoteASelected.construction_breakdown[name][
                                          index
                                        ][value]['cost_per_sqft']
                                      "
                                    />
                                  </b-td>
                                </b-tr>
                              </template>
                              <template v-else>
                                <b-tr
                                  class="border-group-details b"
                                  :key="construction_spec.id"
                                >
                                  <b-td colspan="5">
                                    {{ construction_spec.name }}
                                  </b-td>
                                  <b-td
                                    colspan="3"
                                    v-if="
                                      construction_spec.name == 'Construction Management'
                                    "
                                  >
                                  </b-td>
                                  <b-td colspan="3" v-else>
                                    {{
                                      construction_spec.qty +
                                      " " +
                                      construction_spec.unit_type
                                    }}
                                    <span style="white-space: nowrap" :class="'increase'">
                                      <uil-arrow-up />{{
                                        Number(
                                          Math.abs(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]["qty"]
                                          )
                                        ).toLocaleString("en", {
                                          minimumFractionDigits: 0,
                                        })
                                      }}
                                    </span>
                                  </b-td>
                                  <b-td colspan="2">
                                    {{
                                      "$" +
                                      Number(
                                        construction_spec.cost_per_sqft
                                      ).toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                      })
                                    }}
                                    <span class="increase">
                                      <uil-arrow-up />${{
                                        Number(
                                          Math.abs(
                                            quoteBSelected.construction_breakdown[name][
                                              index
                                            ][value]["cost_per_sqft"]
                                          )
                                        ).toLocaleString("en", {
                                          minimumFractionDigits: 2,
                                        })
                                      }}
                                    </span>
                                  </b-td>
                                </b-tr>
                              </template>
                            </template>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </template>
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        (quoteBSelected.cm_fee_enabled ||
                          quoteASelected.cm_fee_enabled) &&
                        quoteBSelected.cm_fee != quoteASelected.cm_fee
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5"> CM Fee </b-th>
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2">
                            <diff-span
                              :defClass="
                                quoteBSelected.cm_fee / quoteBSelected.sqft >
                                quoteASelected.cm_fee / quoteASelected.sqft
                              "
                              :showSpan="
                                quoteBSelected.cm_fee / quoteBSelected.sqft !=
                                quoteASelected.cm_fee / quoteASelected.sqft
                              "
                              :showArrow="
                                quoteBSelected.cm_fee / quoteBSelected.sqft >
                                quoteASelected.cm_fee / quoteASelected.sqft
                              "
                              :amount="
                                quoteBSelected.cm_fee / quoteBSelected.sqft -
                                quoteASelected.cm_fee / quoteASelected.sqft
                              "
                          /></b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr class="border-group-details">
                          <b-td colspan="5"> CM fee amount </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              Number(
                                quoteBSelected.cm_fee / quoteBSelected.sqft
                              ).toLocaleString("en-CA", {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              })
                            }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBConstructionBaseSpecIncluded !==
                        quoteAConstructionBaseSpecIncluded
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "items INCLUDED in turnkey"
                                : "items INCLUDED in Base Contract"
                            }}
                          </b-th>
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2">
                            <diff-span
                              :defClass="
                                quoteBConstructionBaseSpecIncluded >
                                quoteAConstructionBaseSpecIncluded
                              "
                              :showSpan="
                                quoteBConstructionBaseSpecIncluded !=
                                quoteAConstructionBaseSpecIncluded
                              "
                              :showArrow="
                                quoteBConstructionBaseSpecIncluded >
                                quoteAConstructionBaseSpecIncluded
                              "
                              :amount="
                                quoteBConstructionBaseSpecIncluded -
                                quoteAConstructionBaseSpecIncluded
                              "
                            />
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          class="border-group-details"
                          v-for="(
                            includedInTurnkey, index
                          ) in quoteBConstructionTurnkeyIncluded"
                          :key="index + 'B'"
                        >
                          <b-td colspan="5">
                            {{ includedInTurnkey.name }}
                          </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              "$" +
                              Number(includedInTurnkey.price_per_sqft).toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )
                            }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          class="border-group-details"
                          v-if="quoteBConstructionTurnkeyIncluded.length == 0"
                        >
                          <b-td colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "No items included in turnkey "
                                : "No items included in Base Contract"
                            }}</b-td
                          >
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2"> </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 && ctrItemsB.length > 0) ||
                        ctrItems.length > 0
                      "
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="ctrItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        ctrItemsSubtotalSumB / quoteBSelected.sqft -
                        ctrItemsSubtotalSum / quoteASelected.sqft
                      "
                      :displayDiff="true"
                    />
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 &&
                          allowCstrnItemsB.length > 0) ||
                        allowCstrnItems.length > 0
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowCstrnItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        allowCstrnSubtotalB / quoteBSelected.sqft -
                        allowCstrnSubtotal / quoteASelected.sqft
                      "
                      :displayDiff="true"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="ffeB"
                :title="
                  quoteBSelected.base_spec_version < 3
                    ? 'Furniture & Fixtures'
                    : 'Furniture'
                "
                :hasChanged="
                  (this.quoteBSelected.ffe_toggle
                    ? this.quoteBSelected.ffe_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft
                    : 0) !=
                  (this.quoteASelected.ffe_toggle
                    ? this.quoteASelected.ffe_dollar_per_sqft +
                      ffeSubtotal / this.quoteASelected.sqft
                    : 0)
                "
                :diffClassProp="
                  (this.quoteBSelected.ffe_toggle
                    ? this.quoteBSelected.ffe_dollar_per_sqft +
                      this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft
                    : 0 +
                      this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft +
                      allowFfeSubtotalB / quoteBSelected.sqft) >
                  (this.quoteASelected.ffe_toggle
                    ? this.quoteASelected.ffe_dollar_per_sqft +
                      ffeSubtotal / this.quoteASelected.sqft
                    : 0 +
                      this.quoteASelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotal / this.quoteASelected.sqft +
                      allowFfeSubtotal / quoteASelected.sqft)
                    ? 'increase'
                    : 'decrease'
                "
                :amount="
                  this.quoteBSelected.ffe_toggle
                    ? this.quoteBSelected.ffe_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft +
                      allowFfeSubtotalB / quoteBSelected.sqft
                "
                :difference="
                  (this.quoteBSelected.ffe_toggle
                    ? this.quoteBSelected.ffe_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft +
                      allowFfeSubtotalB / quoteBSelected.sqft) -
                  (this.quoteASelected.ffe_toggle
                    ? this.quoteASelected.ffe_dollar_per_sqft +
                      ffeSubtotal / this.quoteASelected.sqft
                    : this.quoteASelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotal / this.quoteASelected.sqft +
                      allowFfeSubtotal / quoteASelected.sqft)
                "
                :amountClassProp="
                  (this.quoteBSelected.ffe_toggle
                    ? this.quoteBSelected.ffe_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft +
                      ffeSubtotalB / this.quoteBSelected.sqft +
                      allowFfeSubtotalB / quoteBSelected.sqft) ==
                    (this.quoteASelected.ffe_toggle
                      ? this.quoteASelected.ffe_dollar_per_sqft +
                        ffeSubtotal / this.quoteASelected.sqft
                      : this.quoteASelected.ffe_included_turnkey_dollar_per_sqft +
                        ffeSubtotal / this.quoteASelected.sqft +
                        allowFfeSubtotal / quoteASelected.sqft) ||
                  (quoteBSelected.ffe_toggle == false &&
                    quoteASelected.ffe_toggle == false) ||
                  !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  (isQuoteBFfeCollapsable ||
                    ffeItemsB.length > 0 ||
                    allowFfeItemsB.length > 0 ||
                    quoteBSelected.ffe_base_spec_rolled_items !==
                      quoteASelected.ffe_base_spec_rolled_items) &&
                  areWithinTheSameSpec
                "
                :budgetEnabled="quoteBSelected.ffe_toggle"
              >
                <b-col cols="12">
                  <b-table-simple
                    v-if="
                      quoteBSelected.ffe_base_spec_rolled_items !==
                        quoteASelected.ffe_base_spec_rolled_items &&
                      quoteBSelected.ffe_toggle
                    "
                  >
                    <b-tbody v-if="quoteBSelected.base_spec_version >= 3">
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2" class="text-right">
                          <diff-span
                            :defClass="
                              quoteBSelected.ffe_base_spec_rolled_items -
                                (quoteBSelected.base_spec_version >= 3
                                  ? openSpecSubtotalB
                                  : 0) >
                              quoteASelected.ffe_base_spec_rolled_items -
                                (quoteASelected.base_spec_version >= 3
                                  ? openSpecSubtotal
                                  : 0)
                            "
                            :showSpan="
                              quoteBSelected.ffe_base_spec_rolled_items -
                                (quoteBSelected.base_spec_version >= 3
                                  ? openSpecSubtotalB
                                  : 0) !=
                              quoteASelected.ffe_base_spec_rolled_items -
                                (quoteASelected.base_spec_version >= 3
                                  ? openSpecSubtotal
                                  : 0)
                            "
                            :showArrow="
                              quoteBSelected.ffe_base_spec_rolled_items -
                                (quoteBSelected.base_spec_version >= 3
                                  ? openSpecSubtotalB
                                  : 0) >
                              quoteASelected.ffe_base_spec_rolled_items -
                                (quoteASelected.base_spec_version >= 3
                                  ? openSpecSubtotal
                                  : 0)
                            "
                            :amount="
                              quoteBSelected.ffe_base_spec_rolled_items -
                              (quoteBSelected.base_spec_version >= 3
                                ? openSpecSubtotalB
                                : 0) -
                              (quoteASelected.ffe_base_spec_rolled_items -
                                (quoteBSelected.base_spec_version >= 3
                                  ? openSpecSubtotal
                                  : 0))
                            "
                        /></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div style="background: #f8f8f8">
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.ffe_dollar_per_sqft !==
                          quoteASelected.ffe_dollar_per_sqft && quoteBSelected.ffe_toggle
                      "
                    >
                      <b-tbody>
                        <template v-for="(value, name) in quoteBSelected.ffe_breakdown">
                          <template v-for="(ffeItem, index) in value">
                            <b-tr
                              class="border-group-details"
                              :key="ffeItem.id + 'B'"
                              v-if="
                                quoteASelected.ffe_breakdown[name] &&
                                quoteASelected.ffe_breakdown[name][index][
                                  'cost_per_sqft'
                                ] !=
                                  quoteBSelected.ffe_breakdown[name][index][
                                    'cost_per_sqft'
                                  ]
                              "
                            >
                              <b-td colspan="5">
                                {{ ffeItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteBSelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !ffeItem.specification_swap
                                      ? ffeItem.element.specification_key
                                      : ffeItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ ffeItem.qty }}
                                <span
                                  style="white-space: nowrap"
                                  :class="
                                    quoteBSelected.ffe_breakdown[name][index]['qty'] >
                                    quoteASelected.ffe_breakdown[name][index]['qty']
                                      ? 'increase'
                                      : 'decrease'
                                  "
                                >
                                  <uil-arrow-up
                                    v-if="
                                      quoteBSelected.ffe_breakdown[name][index]['qty'] >
                                      quoteASelected.ffe_breakdown[name][index]['qty']
                                    "
                                  />
                                  <uil-arrow-down v-else />{{
                                    Number(
                                      Math.abs(
                                        quoteBSelected.ffe_breakdown[name][index]["qty"] -
                                          quoteASelected.ffe_breakdown[name][index]["qty"]
                                      )
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 0,
                                    })
                                  }}
                                </span>
                              </b-td>
                              <b-td colspan="2">
                                <div v-if="ffeItem.specification_swap == null">
                                  {{
                                    "$" +
                                    Number(
                                      ffeItem.total_price / quoteBSelected.sqft
                                    ).toFixed(2)
                                  }}
                                  <span
                                    style="white-space: nowrap"
                                    :class="
                                      quoteBSelected.ffe_breakdown[name][index][
                                        'cost_per_sqft'
                                      ] >
                                      quoteASelected.ffe_breakdown[name][index][
                                        'cost_per_sqft'
                                      ]
                                        ? 'increase'
                                        : 'decrease'
                                    "
                                  >
                                    <uil-arrow-up
                                      v-if="
                                        quoteBSelected.ffe_breakdown[name][index][
                                          'total_price'
                                        ] /
                                          quoteBSelected.sqft >
                                        quoteASelected.ffe_breakdown[name][index][
                                          'total_price'
                                        ] /
                                          quoteASelected.sqft
                                      "
                                    />
                                    <uil-arrow-down v-else />${{
                                      Number(
                                        Math.abs(
                                          (
                                            quoteBSelected.ffe_breakdown[name][index][
                                              "total_price"
                                            ] / quoteBSelected.sqft
                                          ).toFixed(2) -
                                            (
                                              quoteASelected.ffe_breakdown[name][index][
                                                "total_price"
                                              ] / quoteASelected.sqft
                                            ).toFixed(2)
                                        )
                                      ).toFixed(2)
                                    }}
                                  </span>
                                </div>
                                <div v-else>
                                  {{
                                    "$" +
                                    Number(
                                      (ffeItem.specification_swap.total_price * 1 +
                                        ffeItem.specification_swap
                                          .total_delivery_install *
                                          1) /
                                        quoteBSelected.sqft
                                    ).toFixed(2)
                                  }}
                                  <span
                                    style="white-space: nowrap"
                                    :class="
                                      defineSpanClass(
                                        quoteBSelected.ffe_breakdown[name][index],
                                        quoteASelected.ffe_breakdown[name][index]
                                      )
                                        ? 'increase'
                                        : 'decrease'
                                    "
                                  >
                                    <uil-arrow-up
                                      v-if="
                                        defineArrowToShow(
                                          quoteBSelected.ffe_breakdown[name][index],
                                          quoteASelected.ffe_breakdown[name][index]
                                        )
                                      "
                                    />
                                    <uil-arrow-down v-else />${{
                                      Number(
                                        Math.abs(
                                          diffAmountTotal(
                                            quoteBSelected.ffe_breakdown[name][index],
                                            quoteASelected.ffe_breakdown[name][index]
                                          )
                                        )
                                      ).toFixed(2)
                                    }}
                                  </span>
                                </div>
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              :key="ffeItem.id + 'B'"
                              v-if="!quoteASelected.ffe_breakdown[name]"
                            >
                              <b-td colspan="5">
                                {{ ffeItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteBSelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !ffeItem.specification_swap
                                      ? ffeItem.element.specification_key
                                      : ffeItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ ffeItem.qty }}
                                <span style="white-space: nowrap" class="increase">
                                  <uil-arrow-up />{{
                                    Number(
                                      Math.abs(
                                        quoteBSelected.ffe_breakdown[name][index]["qty"]
                                      )
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 0,
                                    })
                                  }}
                                </span>
                              </b-td>
                              <b-td colspan="2">
                                {{
                                  "$" +
                                  Number(
                                    ffeItem.total_price / quoteBSelected.sqft
                                  ).toFixed(2)
                                }}
                                <span style="white-space: nowrap" class="increase">
                                  <uil-arrow-up />${{
                                    Number(
                                      Math.abs(
                                        quoteBSelected.ffe_breakdown[name][index][
                                          "total_price"
                                        ] / quoteBSelected.sqft
                                      )
                                    ).toFixed(2)
                                  }}
                                </span>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                        <template v-for="(value, name) in quoteASelected.ffe_breakdown">
                          <template v-for="(ffeItem, index) in value">
                            <b-tr
                              class="border-group-details"
                              :key="ffeItem.id + 'B'"
                              v-if="!quoteBSelected.ffe_breakdown[name]"
                            >
                              <b-td colspan="5">
                                {{ ffeItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteBSelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !ffeItem.specification_swap
                                      ? ffeItem.element.specification_key
                                      : ffeItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                0
                                <span style="white-space: nowrap" class="decrease">
                                  <uil-arrow-down />{{
                                    Number(
                                      Math.abs(
                                        quoteASelected.ffe_breakdown[name][index]["qty"]
                                      )
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 0,
                                    })
                                  }}
                                </span>
                              </b-td>
                              <b-td colspan="2">
                                {{ "$" + Number(0).toFixed(2) }}
                                <span style="white-space: nowrap" class="decrease">
                                  <uil-arrow-down />${{
                                    Number(
                                      quoteASelected.ffe_breakdown[name][index][
                                        "total_price"
                                      ] / quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </span>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                      </b-tbody>
                    </b-table-simple>

                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.ffe_included_turnkey_dollar_per_sqft !==
                        quoteASelected.ffe_included_turnkey_dollar_per_sqft
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "items INCLUDED in turnkey"
                                : "items INCLUDED in Base Contract"
                            }}
                          </b-th>
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2">
                            <span
                              :class="
                                quoteBSelected.ffe_included_turnkey_dollar_per_sqft >
                                quoteASelected.ffe_included_turnkey_dollar_per_sqft
                                  ? 'increase'
                                  : 'decrease'
                              "
                              v-if="
                                quoteASelected.ffe_included_turnkey_dollar_per_sqft !=
                                quoteBSelected.ffe_included_turnkey_dollar_per_sqft
                              "
                              style="
                                font-size: 1rem;
                                font-weight: 400;
                                text-align: center !important;
                              "
                            >
                              <uil-arrow-up
                                v-if="
                                  quoteBSelected.ffe_included_turnkey_dollar_per_sqft >
                                  quoteASelected.ffe_included_turnkey_dollar_per_sqft
                                "
                              />
                              <uil-arrow-down v-else />${{
                                Number(
                                  Math.abs(
                                    quoteBSelected.ffe_included_turnkey_dollar_per_sqft -
                                      quoteASelected.ffe_included_turnkey_dollar_per_sqft
                                  )
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              }}
                            </span>
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          class="border-group-details"
                          v-for="(includedInTurnkey, index) in quoteBffeTurnkeyIncluded"
                          :key="index + 'B'"
                        >
                          <b-td colspan="5">
                            {{ includedInTurnkey.name }}
                          </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              "$" +
                              Number(includedInTurnkey.price_per_sqft).toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )
                            }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          class="border-group-details"
                          v-if="quoteBffeTurnkeyIncluded.length == 0"
                        >
                          <b-td colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "No items included in turnkey "
                                : "No items included in Base Contract"
                            }}
                          </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2"> </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 && ffeItemsB.length > 0) ||
                        ffeItems.length > 0
                      "
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="ffeItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        Number((ffeSubtotalB / quoteBSelected.sqft).toFixed(2)) +
                        openSpecSubtotalB -
                        (Number((ffeSubtotal / quoteASelected.sqft).toFixed(2)) +
                          openSpecSubtotal)
                      "
                      :openSpecTotal="openSpecSubtotalB"
                      :displayDiff="true"
                    />
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 &&
                          allowFfeItemsB.length > 0) ||
                        allowFfeItems.length > 0
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowFfeItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        allowFfeSubtotalB / quoteBSelected.sqft -
                        allowFfeSubtotal / quoteASelected.sqft
                      "
                      :displayDiff="true"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="avitB"
                :title="
                  quoteBSelected.base_spec_version < 3
                    ? 'AV / IT'
                    : 'Audio Visual & Networking'
                "
                :hasChanged="
                  (this.quoteBSelected.avit_toggle
                    ? this.quoteBSelected.avit_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft +
                      allowAvISubtotalB / quoteBSelected.sqft) !=
                  (this.quoteASelected.avit_toggle
                    ? this.quoteASelected.avit_dollar_per_sqft +
                      avitSubtotal / this.quoteASelected.sqft
                    : this.quoteASelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotal / this.quoteASelected.sqft +
                      allowAvISubtotalA / quoteASelected.sqft)
                "
                :diffClassProp="
                  (this.quoteBSelected.avit_toggle
                    ? this.quoteBSelected.avit_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft +
                      allowAvISubtotalB / quoteBSelected.sqft) >
                  (this.quoteASelected.avit_toggle
                    ? this.quoteASelected.avit_dollar_per_sqft +
                      avitSubtotal / this.quoteASelected.sqft
                    : this.quoteASelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotal / this.quoteASelected.sqft +
                      allowAvISubtotal / quoteASelected.sqft)
                    ? 'increase'
                    : 'decrease'
                "
                :amount="
                  this.quoteBSelected.avit_toggle
                    ? this.quoteBSelected.avit_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft +
                      allowAvISubtotalB / quoteBSelected.sqft
                "
                :difference="
                  (this.quoteBSelected.avit_toggle
                    ? this.quoteBSelected.avit_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft +
                      allowAvISubtotalB / quoteBSelected.sqft) -
                  (this.quoteASelected.avit_toggle
                    ? this.quoteASelected.avit_dollar_per_sqft +
                      avitSubtotal / this.quoteASelected.sqft
                    : this.quoteASelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotal / this.quoteASelected.sqft +
                      allowAvISubtotal / quoteASelected.sqft)
                "
                :amountClassProp="
                  (this.quoteBSelected.avit_toggle
                    ? this.quoteBSelected.avit_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft
                    : this.quoteBSelected.avit_included_turnkey_dollar_per_sqft +
                      avitSubtotalB / this.quoteBSelected.sqft +
                      allowAvISubtotalB / quoteBSelected.sqft) ==
                    (this.quoteASelected.avit_toggle
                      ? this.quoteASelected.avit_dollar_per_sqft +
                        avitSubtotal / this.quoteASelected.sqft
                      : this.quoteASelected.avit_included_turnkey_dollar_per_sqft +
                        avitSubtotal / this.quoteASelected.sqft -
                        allowAvISubtotal / quoteASelected.sqft) ||
                  (quoteBSelected.avit_toggle == false &&
                    quoteASelected.avit_toggle == false) ||
                  !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  (isQuoteBAvitCollapsable ||
                    avitItemsB.length > 0 ||
                    allowAvItItemsB.length ||
                    quoteBSelected.avit_base_spec_rolled_items !=
                      quoteASelected.avit_base_spec_rolled_items) &&
                  areWithinTheSameSpec
                "
                :budgetEnabled="quoteBSelected.avit_toggle"
              >
                <b-col cols="12">
                  <b-table-simple
                    v-if="
                      quoteBSelected.avit_dollar_per_sqft !==
                        quoteASelected.avit_dollar_per_sqft && quoteBSelected.avit_toggle
                    "
                  >
                    <b-tbody
                      v-if="
                        quoteBSelected.avit_toggle &&
                        quoteBSelected.base_spec_version >= 3 &&
                        quoteBSelected.avit_base_spec_rolled_items !=
                          quoteASelected.avit_base_spec_rolled_items
                      "
                    >
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2" class="text-right">
                          <diff-span
                            :defClass="
                              quoteBSelected.avit_base_spec_rolled_items >
                              quoteASelected.avit_base_spec_rolled_items
                            "
                            :showSpan="
                              quoteBSelected.avit_base_spec_rolled_items !=
                              quoteASelected.avit_base_spec_rolled_items
                            "
                            :showArrow="
                              quoteBSelected.avit_base_spec_rolled_items >
                              quoteASelected.avit_base_spec_rolled_items
                            "
                            :amount="
                              quoteBSelected.avit_base_spec_rolled_items -
                              quoteASelected.avit_base_spec_rolled_items
                            "
                        /></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div style="background: #f8f8f8">
                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.avit_dollar_per_sqft !==
                          quoteASelected.avit_dollar_per_sqft &&
                        quoteBSelected.avit_toggle
                      "
                    >
                      <b-tbody>
                        <template v-for="(value, name) in quoteBSelected.avit_breakdown">
                          <template v-for="(avitItem, index) in value">
                            <b-tr
                              class="border-group-details"
                              :key="avitItem.name + 'B'"
                              v-if="
                                quoteASelected.avit_breakdown[name] &&
                                quoteASelected.avit_breakdown[name][index][
                                  'cost_per_sqft'
                                ] !=
                                  quoteBSelected.avit_breakdown[name][index][
                                    'cost_per_sqft'
                                  ]
                              "
                            >
                              <b-td colspan="5">
                                {{ avitItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteBSelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !avitItem.specification_swap
                                      ? avitItem.element.specification_key
                                      : avitItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ avitItem.qty }}
                                <span
                                  style="white-space: nowrap"
                                  :class="
                                    quoteBSelected.avit_breakdown[name][index]['qty'] >
                                    quoteASelected.avit_breakdown[name][index]['qty']
                                      ? 'increase'
                                      : 'decrease'
                                  "
                                >
                                  <uil-arrow-up
                                    v-if="
                                      quoteBSelected.avit_breakdown[name][index]['qty'] >
                                      quoteASelected.avit_breakdown[name][index]['qty']
                                    "
                                  />
                                  <uil-arrow-down v-else />{{
                                    Number(
                                      Math.abs(
                                        quoteBSelected.avit_breakdown[name][index][
                                          "qty"
                                        ] -
                                          quoteASelected.avit_breakdown[name][index][
                                            "qty"
                                          ]
                                      )
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 0,
                                    })
                                  }}
                                </span>
                              </b-td>
                              <b-td colspan="2">
                                <div v-if="avitItem.specification_swap == null">
                                  {{
                                    "$" +
                                    Number(
                                      avitItem.total_price / quoteBSelected.sqft
                                    ).toFixed(2)
                                  }}
                                  <span
                                    style="white-space: nowrap"
                                    :class="
                                      quoteBSelected.avit_breakdown[name][index][
                                        'cost_per_sqft'
                                      ] >
                                      quoteASelected.avit_breakdown[name][index][
                                        'cost_per_sqft'
                                      ]
                                        ? 'increase'
                                        : 'decrease'
                                    "
                                  >
                                    <uil-arrow-up
                                      v-if="
                                        quoteBSelected.avit_breakdown[name][index][
                                          'total_price'
                                        ] /
                                          quoteBSelected.sqft >
                                        quoteASelected.avit_breakdown[name][index][
                                          'total_price'
                                        ] /
                                          quoteASelected.sqft
                                      "
                                    />
                                    <uil-arrow-down v-else />${{
                                      Number(
                                        Math.abs(
                                          (
                                            quoteBSelected.avit_breakdown[name][index][
                                              "total_price"
                                            ] / quoteBSelected.sqft
                                          ).toFixed(2) -
                                            (
                                              quoteASelected.avit_breakdown[name][index][
                                                "total_price"
                                              ] / quoteASelected.sqft
                                            ).toFixed(2)
                                        )
                                      ).toFixed(2)
                                    }}
                                  </span>
                                </div>
                                <div v-else>
                                  {{
                                    "$" +
                                    Number(
                                      (avitItem.specification_swap.total_price * 1 +
                                        avitItem.specification_swap
                                          .total_delivery_install *
                                          1) /
                                        quoteBSelected.sqft
                                    ).toFixed(2)
                                  }}
                                  <span
                                    style="white-space: nowrap"
                                    :class="
                                      defineSpanClass(
                                        quoteBSelected.avit_breakdown[name][index],
                                        quoteASelected.avit_breakdown[name][index]
                                      )
                                        ? 'increase'
                                        : 'decrease'
                                    "
                                  >
                                    <uil-arrow-up
                                      v-if="
                                        defineArrowToShow(
                                          quoteBSelected.avit_breakdown[name][index],
                                          quoteASelected.avit_breakdown[name][index]
                                        )
                                      "
                                    />
                                    <uil-arrow-down v-else />${{
                                      Number(
                                        Math.abs(
                                          diffAmountTotal(
                                            quoteBSelected.avit_breakdown[name][index],
                                            quoteASelected.avit_breakdown[name][index]
                                          )
                                        )
                                      ).toFixed(2)
                                    }}
                                  </span>
                                </div>
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              :key="avitItem.name + 'B'"
                              v-if="!quoteASelected.avit_breakdown[name]"
                            >
                              <b-td colspan="5">
                                {{ avitItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteBSelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !avitItem.specification_swap
                                      ? avitItem.element.specification_key
                                      : avitItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                {{ avitItem.qty }}
                                <span style="white-space: nowrap" class="increase">
                                  <uil-arrow-up />{{
                                    Number(
                                      Math.abs(
                                        quoteBSelected.avit_breakdown[name][index]["qty"]
                                      )
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 0,
                                    })
                                  }}
                                </span>
                              </b-td>
                              <b-td colspan="2">
                                {{
                                  "$" +
                                  Number(
                                    avitItem.total_price / quoteBSelected.sqft
                                  ).toFixed(2)
                                }}
                                <span style="white-space: nowrap" class="increase">
                                  <uil-arrow-up />${{
                                    Number(
                                      Math.abs(
                                        quoteBSelected.avit_breakdown[name][index][
                                          "total_price"
                                        ] / quoteBSelected.sqft
                                      )
                                    ).toFixed(2)
                                  }}
                                </span>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                        <template v-for="(value, name) in quoteASelected.avit_breakdown">
                          <template v-for="(avitItem, index) in value">
                            <b-tr
                              class="border-group-details"
                              :key="avitItem.name + 'B'"
                              v-if="!quoteBSelected.avit_breakdown[name]"
                            >
                              <b-td colspan="5">
                                {{ avitItem.name }}
                                <div
                                  class="text-muted"
                                  v-if="Number(quoteBSelected.base_spec_version) >= 3.4"
                                >
                                  {{
                                    !avitItem.specification_swap
                                      ? avitItem.element.specification_key
                                      : avitItem.specification_swap.name
                                  }}
                                </div>
                              </b-td>
                              <b-td colspan="3">
                                0
                                <span style="white-space: nowrap" class="decrease">
                                  <uil-arrow-down />{{
                                    Number(
                                      Math.abs(
                                        quoteASelected.avit_breakdown[name][index]["qty"]
                                      )
                                    ).toLocaleString("en", {
                                      minimumFractionDigits: 0,
                                    })
                                  }}
                                </span>
                              </b-td>
                              <b-td colspan="2">
                                {{ "$" + Number(0).toFixed(2) }}
                                <span style="white-space: nowrap" class="decrease">
                                  <uil-arrow-down />${{
                                    Number(
                                      quoteASelected.avit_breakdown[name][index][
                                        "total_price"
                                      ] / quoteASelected.sqft
                                    ).toFixed(2)
                                  }}
                                </span>
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                      </b-tbody>
                    </b-table-simple>

                    <b-table-simple
                      fixed
                      style="margin-bottom: 30px"
                      v-if="
                        quoteBSelected.avit_included_turnkey_dollar_per_sqft !==
                        quoteASelected.avit_included_turnkey_dollar_per_sqft
                      "
                    >
                      <b-thead>
                        <b-tr>
                          <b-th colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "items INCLUDED in turnkey"
                                : "items INCLUDED in Base Contract"
                            }}
                          </b-th>
                          <b-th colspan="3"> </b-th>
                          <b-th colspan="2">
                            <span
                              :class="
                                quoteBSelected.avit_included_turnkey_dollar_per_sqft >
                                quoteASelected.avit_included_turnkey_dollar_per_sqft
                                  ? 'increase'
                                  : 'decrease'
                              "
                              v-if="
                                quoteASelected.avit_included_turnkey_dollar_per_sqft !=
                                quoteBSelected.avit_included_turnkey_dollar_per_sqft
                              "
                              style="
                                font-size: 1rem;
                                font-weight: 400;
                                text-align: center !important;
                              "
                            >
                              <uil-arrow-up
                                v-if="
                                  quoteBSelected.avit_included_turnkey_dollar_per_sqft >
                                  quoteASelected.avit_included_turnkey_dollar_per_sqft
                                "
                              />
                              <uil-arrow-down v-else />${{
                                Number(
                                  Math.abs(
                                    quoteBSelected.avit_included_turnkey_dollar_per_sqft -
                                      quoteASelected.avit_included_turnkey_dollar_per_sqft
                                  )
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              }}
                            </span>
                          </b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          class="border-group-details"
                          v-for="(includedInTurnkey, index) in quoteBAvItTurnkeyIncluded"
                          :key="index + 'B'"
                        >
                          <b-td colspan="5">
                            {{ includedInTurnkey.name }}
                          </b-td>
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2">
                            {{
                              "$" +
                              Number(includedInTurnkey.price_per_sqft).toLocaleString(
                                "en",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )
                            }}
                          </b-td>
                        </b-tr>
                        <b-tr
                          class="border-group-details"
                          v-if="quoteBAvItTurnkeyIncluded.length == 0"
                        >
                          <b-td colspan="5">
                            {{
                              quoteBSelected.base_spec_version < 3
                                ? "No items included in turnkey "
                                : "No items included in Base Contract"
                            }}</b-td
                          >
                          <b-td colspan="3"> </b-td>
                          <b-td colspan="2"> </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 &&
                          avitItemsB.length > 0) ||
                        avitItems.length > 0
                      "
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="avitItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        avitSubtotalB / quoteBSelected.sqft -
                        avitSubtotal / quoteASelected.sqft
                      "
                      :displayDiff="true"
                    />
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 &&
                          allowAvItItemsB.length > 0) ||
                        allowAvItItems.length > 0
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowAvItItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        allowAvISubtotalB / quoteBSelected.sqft -
                        allowAvISubtotal / quoteASelected.sqft
                      "
                      :displayDiff="true"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="siteConditionsB"
                :title="'Site Conditions'"
                :hasChanged="
                  quoteBSelected.site_conditions_dollar_per_sqft !=
                  quoteASelected.site_conditions_dollar_per_sqft
                "
                :diffClassProp="
                  quoteBSelected.site_conditions_dollar_per_sqft >
                  quoteASelected.site_conditions_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="quoteBSelected.site_conditions_dollar_per_sqft"
                :difference="
                  Math.abs(
                    quoteBSelected.site_conditions_dollar_per_sqft -
                      quoteASelected.site_conditions_dollar_per_sqft
                  )
                "
                :amountClassProp="
                  quoteBSelected.site_conditions_dollar_per_sqft ==
                    quoteASelected.site_conditions_dollar_per_sqft ||
                  !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  quoteBSelected.site_conditions_dollar_per_sqft ==
                    quoteASelected.site_conditions_dollar_per_sqft ||
                  !areWithinTheSameSpec
                    ? false
                    : true
                "
                v-if="quoteBSelected.base_spec_version < 3"
              >
                <b-col cols="12" style="background: #f8f8f8">
                  <b-table-simple
                    fixed
                    v-if="
                      quoteBSelected.site_conditions_dollar_per_sqft !=
                      quoteASelected.site_conditions_dollar_per_sqft
                    "
                  >
                    <b-tbody>
                      <b-tr
                        class="border-group-details"
                        v-for="(siteConditions, index) in quoteBSiteConditions"
                        :key="index + 'B'"
                      >
                        <b-td colspan="5">
                          {{ siteConditions.name }}
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2">
                          {{[(siteConditions.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(siteConditions.price_per_sqft)).toLocaleString('en', { minimumFractionDigits: 2 }) + ' /sqft'}}
                        </b-td>
                      </b-tr>
                      <b-tr
                        class="border-group-details"
                        v-if="quoteBSiteConditions.length == 0"
                      >
                        <b-td colspan="5"> No Site Conditions </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                ref="optionsB"
                :title="'Additional Scope & Upgrades'"
                :hasChanged="
                  quoteBSelected.options_dollar_per_sqft !=
                  quoteASelected.options_dollar_per_sqft
                "
                :diffClassProp="
                  quoteBSelected.options_dollar_per_sqft >
                  quoteASelected.options_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="quoteBSelected.options_dollar_per_sqft"
                :difference="
                  Math.abs(
                    quoteBSelected.options_dollar_per_sqft -
                      quoteASelected.options_dollar_per_sqft
                  )
                "
                :amountClassProp="
                  quoteBSelected.options_dollar_per_sqft ==
                    quoteASelected.options_dollar_per_sqft || !areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="
                  quoteBSelected.options_dollar_per_sqft ==
                    quoteASelected.options_dollar_per_sqft || !areWithinTheSameSpec
                    ? false
                    : true
                "
                v-if="quoteBSelected.base_spec_version < 3"
              >
                <b-col cols="12" style="background: #f8f8f8">
                  <b-table-simple
                    fixed
                    v-if="
                      quoteBSelected.options_dollar_per_sqft !=
                      quoteASelected.options_dollar_per_sqft
                    "
                  >
                    <b-tbody>
                      <b-tr
                        class="border-group-details"
                        v-for="(options, index) in quoteBOptions"
                        :key="index + 'B'"
                      >
                        <b-td colspan="5">
                          {{ options.name }}
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2">
                          {{[(options.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(options.price_per_sqft)).toLocaleString('en', { minimumFractionDigits: 2 }) + ' /sqft'}}
                        </b-td>
                      </b-tr>
                      <b-tr class="border-group-details" v-if="quoteBOptions.length == 0">
                        <b-td colspan="5">
                          No Additional Scope & Upgrades for this quote
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2"> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </app-collapse-item>
              <app-collapse-item
                :title="'Clearspace Fee'"
                :hasChanged="
                  quoteBSelected.clearspace_fee_dollar_per_sqft !=
                  quoteASelected.clearspace_fee_dollar_per_sqft
                "
                :diffClassProp="
                  quoteBSelected.clearspace_fee_dollar_per_sqft >
                  quoteASelected.clearspace_fee_dollar_per_sqft
                    ? 'increase'
                    : 'decrease'
                "
                :amount="quoteBSelected.clearspace_fee_dollar_per_sqft"
                :difference="Math.abs(ScGlobalTotalB - ScGlobalTotal)"
                :amountClassProp="
                  quoteBSelected.clearspace_fee_dollar_per_sqft ==
                  quoteASelected.clearspace_fee_dollar_per_sqft
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="false"
                v-if="quoteBSelected.base_spec_version < 3"
              >
              </app-collapse-item>
              <app-collapse-item
                ref="softCostB"
                :title="'Soft Costs'"
                :hasChanged="ScGlobalTotal != ScGlobalTotalB && areWithinTheSameSpec"
                :diffClassProp="ScGlobalTotalB > ScGlobalTotal ? 'increase' : 'decrease'"
                :amount="ScGlobalTotalB"
                :difference="ScGlobalTotalB - ScGlobalTotal"
                :amountClassProp="
                  ScGlobalTotal == ScGlobalTotalB && areWithinTheSameSpec
                    ? 'muted'
                    : 'different'
                "
                :collapsableItem="ScGlobalTotal != ScGlobalTotalB && areWithinTheSameSpec"
                v-if="quoteBSelected.base_spec_version >= 3"
              >
                <b-col cols="12">
                  <b-table-simple
                    v-if="
                      quoteBSelected.sc_base_spec_rolled_items !=
                      quoteASelected.sc_base_spec_rolled_items
                    "
                  >
                    <b-tbody>
                      <b-tr class="border-group-details">
                        <b-td colspan="5" class="m-0 p-0" style="font-weight: 600">
                          BASE CONTRACT
                        </b-td>
                        <b-td colspan="3"> </b-td>
                        <b-td colspan="2" class="text-right">
                          <diff-span
                            :defClass="
                              quoteBSelected.sc_base_spec_rolled_items >
                              quoteASelected.sc_base_spec_rolled_items
                            "
                            :showSpan="
                              quoteBSelected.sc_base_spec_rolled_items !=
                              quoteASelected.sc_base_spec_rolled_items
                            "
                            :showArrow="
                              quoteBSelected.sc_base_spec_rolled_items >
                              quoteASelected.sc_base_spec_rolled_items
                            "
                            :amount="
                              quoteBSelected.sc_base_spec_rolled_items -
                              quoteASelected.sc_base_spec_rolled_items
                            "
                        /></b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <!--  v-if="ScGlobalTotal !== ScGlobalTotalB" -->
                  <div
                    style="background: #f8f8f8"
                    v-if="
                      quoteBSelected.sc_base_spec_rolled_items !=
                      quoteASelected.sc_base_spec_rolled_items
                    "
                  >
                    <b-table-simple fixed style="margin-bottom: 30px">
                      <b-tbody>
                        <template>
                          <!-- v-if="
                                quoteASelected.construction_pm_buffer * 1 !=
                                quoteBSelected.construction_pm_buffer * 1
                              " -->
                          <template>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.design_ame_buffer !=
                                quoteASelected.design_ame_buffer
                              "
                            >
                              <b-td colspan="5"> Design + Architectural </b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteBSelected.design_ame_buffer * 1
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}
                                <diff-span
                                  :defClass="
                                    quoteBSelected.design_ame_buffer * 1 >
                                    quoteASelected.design_ame_buffer * 1
                                  "
                                  :showSpan="
                                    quoteBSelected.design_ame_buffer * 1 !=
                                    quoteASelected.design_ame_buffer * 1
                                  "
                                  :showArrow="
                                    quoteBSelected.design_ame_buffer * 1 >
                                    quoteASelected.design_ame_buffer * 1
                                  "
                                  :amount="
                                    quoteBSelected.design_ame_buffer * 1 -
                                    quoteASelected.design_ame_buffer * 1
                                  "
                                />
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.construction_pm_buffer !=
                                quoteASelected.construction_pm_buffer
                              "
                            >
                              <b-td colspan="5"> Project Management </b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteBSelected.construction_pm_buffer * 1
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}
                                <diff-span
                                  :defClass="
                                    quoteBSelected.construction_pm_buffer * 1 >
                                    quoteASelected.construction_pm_buffer * 1
                                  "
                                  :showSpan="
                                    quoteBSelected.construction_pm_buffer * 1 !=
                                    quoteASelected.construction_pm_buffer * 1
                                  "
                                  :showArrow="
                                    quoteBSelected.construction_pm_buffer * 1 >
                                    quoteASelected.construction_pm_buffer * 1
                                  "
                                  :amount="
                                    quoteBSelected.construction_pm_buffer * 1 -
                                    quoteASelected.construction_pm_buffer * 1
                                  "
                                />
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.engineering_fee_price !=
                                quoteASelected.engineering_fee_price
                              "
                            >
                              <b-td colspan="5"> Engineering Fee </b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteBSelected.engineering_fee_price * 1
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}<diff-span
                                  :defClass="
                                    quoteBSelected.engineering_fee_price * 1 >
                                    quoteASelected.engineering_fee_price * 1
                                  "
                                  :showSpan="
                                    quoteBSelected.engineering_fee_price * 1 !=
                                    quoteASelected.engineering_fee_price * 1
                                  "
                                  :showArrow="
                                    quoteBSelected.engineering_fee_price * 1 >
                                    quoteASelected.engineering_fee_price * 1
                                  "
                                  :amount="
                                    quoteBSelected.engineering_fee_price * 1 -
                                    quoteASelected.engineering_fee_price * 1
                                  "
                                />
                              </b-td>
                            </b-tr>
                            <b-tr
                              class="border-group-details"
                              v-if="
                                quoteBSelected.permit_fees / quoteBSelected.sqft !=
                                quoteASelected.permit_fees / quoteASelected.sqft
                              "
                            >
                              <b-td colspan="5"> Permits</b-td>
                              <b-td colspan="3"> </b-td>
                              <b-td colspan="2">
                                {{
                                  Number(
                                    quoteBSelected.permit_fees / quoteBSelected.sqft
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                }}<diff-span
                                  :defClass="
                                    quoteBSelected.permit_fees / quoteBSelected.sqft >
                                    quoteASelected.permit_fees / quoteASelected.sqft
                                  "
                                  :showSpan="
                                    quoteBSelected.permit_fees / quoteBSelected.sqft !=
                                    quoteASelected.permit_fees / quoteASelected.sqft
                                  "
                                  :showArrow="
                                    quoteBSelected.permit_fees / quoteBSelected.sqft >
                                    quoteASelected.permit_fees / quoteASelected.sqft
                                  "
                                  :amount="
                                    quoteBSelected.permit_fees / quoteBSelected.sqft -
                                    quoteASelected.permit_fees / quoteASelected.sqft
                                  "
                                />
                              </b-td>
                            </b-tr>
                          </template>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                  <div>
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 && scItemsB.length > 0) ||
                        scItems.length > 0
                      "
                      :title="'BASE CONTRACT ADDITIONS'"
                      :items="scItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        Number((scSubtotalB / quoteBSelected.sqft).toFixed(2)) -
                        Number((scSubtotal / quoteASelected.sqft).toFixed(2))
                      "
                      :displayDiff="true"
                    />
                    <additions-cash-allowance
                      :show="
                        (quoteBSelected.base_spec_version >= 3 &&
                          allowSCItemsB.length > 0) ||
                        allowSCItems.length > 0
                      "
                      :title="'CASH ALLOWANCES'"
                      :items="allowSCItemsB"
                      :sqft="quoteBSelected.sqft"
                      :diffAmount="
                        Number((allowSCSubtotalB / quoteBSelected.sqft).toFixed(2)) -
                        Number((allowSCSubtotal / quoteASelected.sqft).toFixed(2))
                      "
                      :displayDiff="true"
                    />
                  </div>
                </b-col>
              </app-collapse-item>
            </app-collapse>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
  BFormGroup,
  BBadge,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { UilRuler, UilArrowUp, UilArrowDown } from "@iconscout/vue-unicons";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItemQuoteCompare.vue";
import AdditionsCashAllowance from "./Components/AdditionsCashAllowance.vue";
import DiffSpan from "./Components/Utils/DiffSpan.vue";
import { forEach } from "postcss-rtl/lib/affected-props";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
    vSelect,
    BFormGroup,
    UilRuler,
    UilArrowUp,
    UilArrowDown,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    AdditionsCashAllowance,
    DiffSpan,
  },
  data() {
    return {
      quoteId: Number(this.$route.params.quoteid),
      opportunityId: this.$route.params.opportunityid,
      quote: {
        opportunity: {},
        comments: {},
      },
      comments: {},
      options: {},
      siteConditions: {},
      quotes: [],
      opportunity: {},
      show: true,
      newComment: "",
      tfr: "",
      quoteAId: null,
      quoteBId: null,
      quoteASelected: [],
      quoteBSelected: [],
      quoteBavit: [],
      sortingArr: [
        "Drywall",
        "Glazing",
        "Kitchen & Millwork",
        "Doors",
        "Flooring",
        "Electrical (by Space)",
        "Electrical (Takeoff)",
        "Lighting",
        "Mechanical",
        "Wall Finishes",
        "Suite/General Conditions",
        "Construction Management Fee",
      ],
      sortingArrv2: [
        "Drywall",
        "Glazing",
        "Kitchen & Millwork",
        "Doors",
        "Flooring",
        "Electrical (by Space)",
        "Electrical (Takeoff)",
        "Lighting",
        "Mechanical",
        "Wall Finishes",
        "General Requirements",
        "Closeout & Other",
      ],
    };
  },
  beforeMount() {
    this.diffTool();
  },
  computed: {
    quoteAConstructionTurnkeyIncluded() {
      const options = this.quoteASelected.options.filter(
        (n) =>
          n.budget_group == "Construction" &&
          n.is_base_spec == true &&
          n.is_adjustment == false
      );
      const siteConditions = this.quoteASelected.site_conditions.filter(
        (n) => n.budget_group == "Construction" && n.is_base_spec == true
      );
      const turnkey = options.concat(siteConditions);
      return turnkey;
    },
    quoteBConstructionTurnkeyIncluded() {
      const options = this.quoteBSelected.options.filter(
        (n) =>
          n.budget_group == "Construction" &&
          n.is_base_spec == true &&
          n.is_adjustment == false
      );
      const siteConditions = this.quoteBSelected.site_conditions.filter(
        (n) => n.budget_group == "Construction" && n.is_base_spec == true
      );
      const turnkey = options.concat(siteConditions);
      return turnkey;
    },
    quoteAAvItTurnkeyIncluded() {
      const options = this.quoteASelected.options.filter(
        (n) => n.budget_group == "AV/IT" && n.is_base_spec == true
      );
      return options;
    },
    quoteBAvItTurnkeyIncluded() {
      const options = this.quoteBSelected.options.filter(
        (n) => n.budget_group == "AV/IT" && n.is_base_spec == true
      );
      return options;
    },
    quoteAffeTurnkeyIncluded() {
      const options = this.quoteASelected.options.filter(
        (n) => n.budget_group == "FF&E" && n.is_base_spec == true
      );
      return options;
    },
    quoteBffeTurnkeyIncluded() {
      const options = this.quoteBSelected.options.filter(
        (n) => n.budget_group == "FF&E" && n.is_base_spec == true
      );
      return options;
    },
    quoteASiteConditions() {
      const siteConditions = this.quoteASelected.site_conditions.filter(
        (n) => n.is_base_spec == false
      );
      return siteConditions;
    },
    quoteBSiteConditions() {
      const siteConditions = this.quoteBSelected.site_conditions.filter(
        (n) => n.is_base_spec == false
      );
      return siteConditions;
    },
    quoteAOptions() {
      const Options = this.quoteASelected.options.filter((n) => n.is_base_spec == false);
      return Options;
    },
    quoteBOptions() {
      const Options = this.quoteBSelected.options.filter((n) => n.is_base_spec == false);
      return Options;
    },
    differenceAmount() {
      let total = 0;
      if (
        this.quoteASelected.avit_toggle == false &&
        this.quoteBSelected.avit_toggle == false
      ) {
        total = 0;
      } else if (
        this.quoteASelected.avit_toggle == true &&
        this.quoteBSelected.avit_toggle == false
      ) {
        total = Math.abs(0 - this.quoteASelected.avit_dollar_per_sqft);
      } else if (
        this.quoteASelected.avit_toggle == false &&
        this.quoteBSelected.avit_toggle == true
      ) {
        total = Math.abs(0 - this.quoteBSelected.avit_dollar_per_sqft);
      } else {
        total = Math.abs(
          this.quoteBSelected.avit_dollar_per_sqft -
            this.quoteASelected.avit_dollar_per_sqft
        );
      }

      return total;
    },
    quoteBAvIt() {
      const avit = [];
      Object.entries(this.quoteASelected.avit_breakdown).forEach(([key, value]) => {
        if (this.quoteBSelected.avit_breakdown[key]) {
          avit.push({
            name: this.quoteBSelected.avit_breakdown[key][0]["name"],
            qty: this.quoteBSelected.avit_breakdown[key][0]["qty"],
          });
        }
      });
      console.log(avit);
      return avit;
    },
    isQuoteBFfeCollapsable() {
      //If quote B has FFE turned OFF and doesn't have any additional scope and upgrades included in turnkey
      if (
        this.quoteBSelected.ffe_toggle == false &&
        this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft == 0
      ) {
        //we hide the collapsable toggle
        return false;
        //If quote B has FFE budget total and any additional scope and upgrades included in turnkey are equal to quote A totals with included turnkey
      } else if (!this.areWithinTheSameSpec) {
        //If there quotes have different base specs 3.x with 2.x we disable the collaps
        //we show collapsable
        return false;
      } else if (
        this.quoteASelected.ffe_toggle == false &&
        this.quoteBSelected.ffe_toggle == true
      ) {
        //we shop collapsable option
        return true;
      } else if (
        this.quoteBSelected.ffe_dollar_per_sqft +
          this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft +
          this.ffeSubtotalB +
          this.allowFfeSubtotalB ==
        this.quoteASelected.ffe_dollar_per_sqft +
          this.quoteASelected.ffe_included_turnkey_dollar_per_sqft +
          this.ffeSubtotal +
          this.allowFfeSubtotal
      ) {
        //we hide the collapsable (both are the same)
        return false;
        //If quote B has FFE budget total and any additional scope and upgrades included in turnkey are different to quote A totals with included turnkey
      } else if (
        this.quoteBSelected.ffe_dollar_per_sqft +
          this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft !=
        this.quoteASelected.ffe_dollar_per_sqft +
          this.quoteASelected.ffe_included_turnkey_dollar_per_sqft
      ) {
        //we show collapsable
        return true;
        //If quote B FFE budget is disabled and has additional scope and upgrades included in turnkey
      } else if (
        this.quoteBSelected.ffe_toggle == false &&
        this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft > 0
      ) {
        //we shop collapsable option
        return true;
      } else {
        return true;
      }
    },
    isQuoteAFfeCollapsable() {
      //If quote B has FFE turned OFF and doesn't have any additional scope and upgrades included in turnkey
      if (
        this.quoteASelected.ffe_toggle == false &&
        this.quoteASelected.ffe_included_turnkey_dollar_per_sqft == 0
      ) {
        //we hide the collapsable toggle
        return false;
        //If quote B has FFE budget total and any additional scope and upgrades included in turnkey are equal to quote A totals with included turnkey
      } else if (!this.areWithinTheSameSpec) {
        //If there quotes have different base specs 3.x with 2.x we disable the collaps
        //we show collapsable
        return false;
      } else if (
        this.quoteASelected.ffe_toggle == false &&
        this.quoteBSelected.ffe_toggle == true
      ) {
        //we show collapsable option
        return true;
      } else if (
        this.quoteBSelected.ffe_dollar_per_sqft +
          this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft +
          this.ffeSubtotalB +
          this.allowFfeSubtotalB ==
        this.quoteASelected.ffe_dollar_per_sqft +
          this.quoteASelected.ffe_included_turnkey_dollar_per_sqft +
          this.ffeSubtotal +
          this.allowFfeSubtotal
      ) {
        //we hide the collapsable (both are the same)
        return false;
        //If quote B has FFE budget total and any additional scope and upgrades included in turnkey are different to quote A totals with included turnkey
      } else if (
        this.quoteBSelected.ffe_dollar_per_sqft +
          this.quoteBSelected.ffe_included_turnkey_dollar_per_sqft !=
        this.quoteASelected.ffe_dollar_per_sqft +
          this.quoteASelected.ffe_included_turnkey_dollar_per_sqft
      ) {
        //we show collapsable
        return true;
        //If quote B FFE budget is disabled and has additional scope and upgrades included in turnkey
      } else if (
        this.quoteASelected.ffe_toggle == false &&
        this.quoteASelected.ffe_included_turnkey_dollar_per_sqft > 0
      ) {
        //we shop collapsable option
        return true;
      } else {
        return true;
      }
    },
    isQuoteBAvitCollapsable() {
      //If quote B has FFE turned OFF and doesn't have any additional scope and upgrades included in turnkey
      if (
        this.quoteBSelected.avit_toggle == false &&
        this.quoteBSelected.avit_included_turnkey_dollar_per_sqft == 0
      ) {
        //we hide the collapsable toggle
        return false;
        //If quote B has avit budget total and any additional scope and upgrades included in turnkey are equal to quote A totals with included turnkey
      } else if (!this.areWithinTheSameSpec) {
        //If there quotes have different base specs 3.x with 2.x we disable the collaps
        //we show collapsable
        return false;
      } else if (
        this.quoteASelected.avit_toggle == false &&
        this.quoteBSelected.avit_toggle == true
      ) {
        //we shop collapsable option
        return true;
      } else if (
        this.quoteBSelected.avit_dollar_per_sqft +
          this.quoteBSelected.avit_included_turnkey_dollar_per_sqft +
          this.avitSubtotalB +
          this.allowAvISubtotalB ==
        this.quoteASelected.avit_dollar_per_sqft +
          this.quoteASelected.avit_included_turnkey_dollar_per_sqft +
          this.avitSubtotal +
          this.allowAvISubtotal
      ) {
        //we hide the collapsable (both are the same)
        return false;
        //If quote B has avit budget total and any additional scope and upgrades included in turnkey are diavitrent to quote A totals with included turnkey
      } else if (
        this.quoteBSelected.avit_dollar_per_sqft +
          this.quoteBSelected.avit_included_turnkey_dollar_per_sqft !=
        this.quoteASelected.avit_dollar_per_sqft +
          this.quoteASelected.avit_included_turnkey_dollar_per_sqft
      ) {
        //we show collapsable
        return true;
        //If quote B avit budget is disabled and has additional scope and upgrades included in turnkey
      } else if (
        this.quoteBSelected.avit_toggle == false &&
        this.quoteBSelected.avit_included_turnkey_dollar_per_sqft > 0
      ) {
        //we shop collapsable option
        return true;
      } else {
        return true;
      }
    },
    isQuoteAAvitCollapsable() {
      //If quote B has FFE turned OFF and doesn't have any additional scope and upgrades included in turnkey
      if (
        this.quoteASelected.avit_toggle == false &&
        this.quoteASelected.avit_included_turnkey_dollar_per_sqft == 0
      ) {
        //we hide the collapsable toggle
        return false;
        //If quote B has avit budget total and any additional scope and upgrades included in turnkey are equal to quote A totals with included turnkey
      } else if (!this.areWithinTheSameSpec) {
        //If there quotes have different base specs 3.x with 2.x we disable the collaps
        //we show collapsable
        return false;
      } else if (
        this.quoteASelected.avit_toggle == false &&
        this.quoteBSelected.avit_toggle == true
      ) {
        //we shop collapsable option
        return true;
      } else if (
        this.quoteBSelected.avit_dollar_per_sqft +
          this.quoteBSelected.avit_included_turnkey_dollar_per_sqft +
          this.avitSubtotalB +
          this.allowAvISubtotalB ==
        this.quoteASelected.avit_dollar_per_sqft +
          this.quoteASelected.avit_included_turnkey_dollar_per_sqft +
          this.avitSubtotal +
          this.allowAvISubtotal
      ) {
        //we hide the collapsable (both are the same)
        return false;
        //If quote B has avit budget total and any additional scope and upgrades included in turnkey are diavitrent to quote A totals with included turnkey
      } else if (
        this.quoteBSelected.avit_dollar_per_sqft +
          this.quoteBSelected.avit_included_turnkey_dollar_per_sqft !=
        this.quoteASelected.avit_dollar_per_sqft +
          this.quoteASelected.avit_included_turnkey_dollar_per_sqft
      ) {
        //we show collapsable
        return true;
        //If quote B avit budget is disabled and has additional scope and upgrades included in turnkey
      } else if (
        this.quoteASelected.avit_toggle == false &&
        this.quoteASelected.avit_included_turnkey_dollar_per_sqft > 0
      ) {
        //we shop collapsable option
        return true;
      }
    },
    areWithinTheSameSpec() {
      let areComparable = false;
      if (
        Number(this.quoteASelected.base_spec_version) >= 3 &&
        Number(this.quoteBSelected.base_spec_version) >= 3
      ) {
        areComparable = true;
      } else if (
        Number(this.quoteASelected.base_spec_version) < 3 &&
        Number(this.quoteBSelected.base_spec_version < 3)
      ) {
        areComparable = true;
      }
      return areComparable;
    },
    // start additions allowances funcs
    // * Quote A CSTRN Allowances/Options
    ctrItemsSubtotalSum() {
      let result = this.quoteASelected.options
        .filter((c) => c.budget_group == "Construction" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ctrItems() {
      let result = this.quoteASelected.options.filter(
        (c) => c.budget_group == "Construction" && !c.is_base_spec
      );
      return result;
    },
    allowCstrnItems() {
      let result = this.quoteASelected.allowances.filter(
        (c) => c.budget_group == "Construction"
      );
      return result;
    },
    allowCstrnSubtotal() {
      let result = this.quoteASelected.allowances
        .filter((c) => c.budget_group == "Construction")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ctrItemsSubtotal() {
      let result = this.quoteASelected.construction_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + total,
        0
      );
      return result;
    },
    // * Quote A FFE Allowances/Options
    ffeSubtotal() {
      let result = this.quoteASelected.options
        .filter((c) => c.budget_group == "FF&E" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeItems() {
      let result = this.quoteASelected.options.filter(
        (c) => c.budget_group == "FF&E" && !c.is_base_spec
      );
      return result;
    },
    allowFfeItems() {
      let result = this.quoteASelected.allowances.filter((c) => c.budget_group == "FF&E");
      return result;
    },
    allowFfeSubtotal() {
      let result = this.quoteASelected.allowances
        .filter((c) => c.budget_group == "FF&E")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    // * Quote A AvIt Allowances/Options
    avitItems() {
      let result = this.quoteASelected.options.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    avitSubtotal() {
      let result = this.quoteASelected.options
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },

    allowAvItItems() {
      let result = this.quoteASelected.allowances.filter(
        (c) => c.budget_group == "AV/IT"
      );
      return result;
    },
    allowAvISubtotal() {
      let result = this.quoteASelected.allowances
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    // * Quote A SC funcs
    ScGlobalTotal() {
      return Number(
        (
          this.quoteASelected.construction_pm_buffer * 1 +
          this.quoteASelected.design_ame_buffer * 1 +
          Number(this.quoteASelected.engineering_fee_price) +
          Number(
            Number(this.quoteASelected.permit_fees) / Number(this.quoteASelected.sqft)
          ) +
          (Number(this.allowSCSubtotal) + Number(this.scSubtotal)) /
            this.quoteASelected.sqft
        ).toFixed(2)
      );
    },
    scSubtotal() {
      let result = this.quoteASelected.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    scItems() {
      let result = this.quoteASelected.options.filter(
        (c) => c.budget_group == "Soft Cost"
      );
      return result;
    },
    allowSCItems() {
      let result = this.quoteASelected.allowances.filter(
        (c) => c.budget_group == "Soft Cost"
      );
      return result;
    },
    allowSCSubtotal() {
      let result = this.quoteASelected.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    // Start Quote B funcs
    // * Quote B CSTRN Allowances/Options
    ctrItemsSubtotalSumB() {
      let result = this.quoteBSelected.options
        .filter((c) => c.budget_group == "Construction" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ctrItemsB() {
      let result = this.quoteBSelected.options.filter(
        (c) => c.budget_group == "Construction" && !c.is_base_spec
      );
      return result;
    },
    allowCstrnItemsB() {
      let result = this.quoteBSelected.allowances.filter(
        (c) => c.budget_group == "Construction"
      );
      return result;
    },
    allowCstrnSubtotalB() {
      let result = this.quoteBSelected.allowances
        .filter((c) => c.budget_group == "Construction")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ctrItemsSubtotalB() {
      let result = this.quoteBSelected.construction_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + total,
        0
      );
      return result;
    },
    // * Quote B FFE Allowances/Options
    ffeSubtotalB() {
      let result = this.quoteBSelected.options
        .filter((c) => c.budget_group == "FF&E" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeItemsB() {
      let result = this.quoteBSelected.options.filter(
        (c) => c.budget_group == "FF&E" && !c.is_base_spec
      );
      return result;
    },
    allowFfeItemsB() {
      let result = this.quoteBSelected.allowances.filter((c) => c.budget_group == "FF&E");
      return result;
    },
    allowFfeSubtotalB() {
      let result = this.quoteBSelected.allowances
        .filter((c) => c.budget_group == "FF&E")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    // * Quote B AvIt Allowances/Options
    avitItemsB() {
      let result = this.quoteBSelected.options.filter((c) => c.budget_group == "AV/IT");
      return result;
    },
    avitSubtotalB() {
      let result = this.quoteBSelected.options
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },

    allowAvItItemsB() {
      let result = this.quoteBSelected.allowances.filter(
        (c) => c.budget_group == "AV/IT"
      );
      return result;
    },
    allowAvISubtotalB() {
      let result = this.quoteBSelected.allowances
        .filter((c) => c.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    // * Quote B SC funcs
    ScGlobalTotalB() {
      return Number(
        (
          this.quoteBSelected.construction_pm_buffer * 1 +
          this.quoteBSelected.design_ame_buffer * 1 +
          Number(this.quoteBSelected.engineering_fee_price) +
          Number(
            Number(this.quoteBSelected.permit_fees) / Number(this.quoteBSelected.sqft)
          ) +
          (Number(this.allowSCSubtotalB) + Number(this.scSubtotalB)) /
            this.quoteBSelected.sqft
        ).toFixed(2)
      );
    },
    scSubtotalB() {
      let result = this.quoteBSelected.options
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    scItemsB() {
      let result = this.quoteBSelected.options.filter(
        (c) => c.budget_group == "Soft Cost"
      );
      return result;
    },
    allowSCItemsB() {
      let result = this.quoteBSelected.allowances.filter(
        (c) => c.budget_group == "Soft Cost"
      );
      return result;
    },
    allowSCSubtotalB() {
      let result = this.quoteBSelected.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    // get BaseContract Totals Quote A
    quoteAConstructionBaseSpecIncluded() {
      const options = this.quoteASelected.options
        .filter(
          (n) =>
            n.budget_group == "Construction" &&
            n.is_base_spec == true &&
            n.is_adjustment == false
        )
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      const siteConditions = this.quoteASelected.site_conditions
        .filter((n) => n.budget_group == "Construction" && n.is_base_spec == true)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      const turnkey = options + siteConditions;

      return turnkey / this.quoteASelected.sqft;
    },
    quoteBConstructionBaseSpecIncluded() {
      const options = this.quoteBSelected.options
        .filter(
          (n) =>
            n.budget_group == "Construction" &&
            n.is_base_spec == true &&
            n.is_adjustment == false
        )
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      const siteConditions = this.quoteBSelected.site_conditions
        .filter((n) => n.budget_group == "Construction" && n.is_base_spec == true)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      const turnkey = options + siteConditions;
      return turnkey / this.quoteBSelected.sqft;
    },
    // open specs funcs
    openSpecSubtotal() {
      let sum = 0;
      // Assuming quoteBSelected.ffe_breakdown is an object
      const ffeBreakdown = this.quoteASelected.ffe_breakdown;
      const ffeBreakdownArray = Object.entries(ffeBreakdown);

      for (const [name, value] of ffeBreakdownArray) {
        // name is the property name
        // value is the property value

        for (const item of value) {
          if (item.element.vendor == "[OPEN SPEC]") {
            sum += item.supply_price * item.qty;
          }
        }
      }
      // return Number((sum / this.quoteASelected.sqft).toFixed(2));
      return 0;
    },
    openSpecSubtotalB() {
      let sum = 0;
      // Assuming quoteBSelected.ffe_breakdown is an object
      const ffeBreakdown = this.quoteBSelected.ffe_breakdown;
      const ffeBreakdownArray = Object.entries(ffeBreakdown);

      for (const [name, value] of ffeBreakdownArray) {
        // name is the property name
        // value is the property value

        for (const item of value) {
          if (item.element.vendor == "[OPEN SPEC]") {
            sum += item.supply_price * item.qty;
          }
        }
      }
      // return Number((sum / this.quoteBSelected.sqft).toFixed(2));
      return 0;
    },
  },

  methods: {
    diffTool() {
      this.$http
        .get(`/quote/quote-diff/${this.opportunityId}`)
        .then((response) => {
          this.quotes = response.data.quotes;
          (this.opportunity = response.data.opportunity), (this.show = false);
          this.updateBreadcrumb();
          if (this.quoteId) {
            this.quoteAId = this.quoteId;
            this.quoteAChange(this.quoteId);
          }
        })
        .catch((error) => {
          this.showToast("danger", error.response.data.message);
        });
    },
    sortedCategories(category) {
      const arr = [];
      for (
        let index = 0;
        index < this.quoteASelected.construction_breakdown[category].length;
        index++
      ) {
        if (
          this.quoteBSelected.construction_breakdown[category][index]["cost_per_sqft"] !=
          this.quoteASelected.construction_breakdown[category][index]["cost_per_sqft"]
        ) {
          this.quoteBSelected.construction_breakdown[category][index]["diff"] = Math.abs(
            this.quoteBSelected.construction_breakdown[category][index]["cost_per_sqft"] -
              this.quoteASelected.construction_breakdown[category][index]["cost_per_sqft"]
          );
          this.quoteASelected.construction_breakdown[category][index]["diff"] = Math.abs(
            this.quoteBSelected.construction_breakdown[category][index]["cost_per_sqft"] -
              this.quoteASelected.construction_breakdown[category][index]["cost_per_sqft"]
          );
        } else {
          this.quoteBSelected.construction_breakdown[category][index]["diff"] = 0;
          this.quoteASelected.construction_breakdown[category][index]["diff"] = 0;
        }
      }
    },
    sortedCategories2() {
      return sorted;
    },

    /**
     * Determines whether to display the category table based on the comparison
     * of the total cost per square foot for a specific category between quoteB and quoteA.
     *
     * @param {string} category - The category to check the cost for.
     * @returns {boolean} - Returns true if the total cost per sqft for the category
     *                      in quoteBSelected is different from quoteASelected; otherwise, false.
     */
    showCategoryTable(category) {
      // Initialize total costs as 0
      let totalCostQuoteB = 0;
      let totalCostQuoteA = 0;

      // Check if category exists in quoteBSelected and then calculate the total cost per sqft
      if (category == "General Requirements") {
        if (this.quoteBSelected.construction_breakdown_ungrouped[category]) {
          totalCostQuoteB = this.quoteBSelected.construction_breakdown_ungrouped[
            category
          ].reduce((total, obj) => obj.total_price + total, 0);
        }

        // Check if category exists in quoteASelected and then calculate the total cost per sqft
        if (this.quoteASelected.construction_breakdown_ungrouped[category]) {
          totalCostQuoteA = this.quoteASelected.construction_breakdown_ungrouped[
            category
          ].reduce((total, obj) => obj.total_price + total, 0);
        }
      } else {
        if (this.quoteBSelected.construction_breakdown_ungrouped[category]) {
          totalCostQuoteB = this.quoteBSelected.construction_breakdown_ungrouped[
            category
          ].reduce((total, obj) => obj.cost_per_sqft + total, 0);
        }

        // Check if category exists in quoteASelected and then calculate the total cost per sqft
        if (this.quoteASelected.construction_breakdown_ungrouped[category]) {
          totalCostQuoteA = this.quoteASelected.construction_breakdown_ungrouped[
            category
          ].reduce((total, obj) => obj.cost_per_sqft + total, 0);
        }
      }

      // Compare the total costs and return the result

      return totalCostQuoteB !== totalCostQuoteA && this.areWithinTheSameSpec;
    },

    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = `${this.opportunity.address}, ${this.opportunity.geography}`;
      if (this.opportunity.marked_as_active) {
        this.$route.meta.breadcrumb[0].to = `/active/${this.$route.params.opportunityid}`;
      } else {
        this.$route.meta.breadcrumb[0].to = `/opportunities/${this.$route.params.opportunityid}`;
      }
      this.$route.meta.breadcrumb[0].active = false;
      //this.$route.meta.breadcrumb[2].text = `Quote #${this.quote.tfr_name}`
      this.$root.$emit("rerender-breadcrumb");
    },
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();

      return initial;
    },
    quoteAChange(quote) {
      this.quoteASelected = this.quotes.find(function (e) {
        return e.id == quote;
      });
    },
    quoteBChange(quote) {
      this.quoteBSelected = this.quotes.find(function (e) {
        return e.id == quote;
      });
    },
    quoteAChange2(quote) {
      this.quoteASelected = this.quotes.find(function (e) {
        return e.id == quote;
      });
      this.$refs.constructionA.updateVisible(false);
      this.$refs.ffeA.updateVisible(false);
      this.$refs.avitA.updateVisible(false);
      this.$refs.siteConditionsA.updateVisible(false);
      this.$refs.optionsA.updateVisible(false);
    },
    quoteBChange2(quote) {
      this.quoteBSelected = this.quotes.find(function (e) {
        return e.id == quote;
      });
      this.$refs.constructionB.updateVisible(false);
      this.$refs.ffeB.updateVisible(false);
      this.$refs.avitB.updateVisible(false);
      this.$refs.siteConditionsB.updateVisible(false);
      this.$refs.optionsB.updateVisible(false);
    },
    constructionAdjustmentTotalQuantityB(constructionWorkId) {
      var sum = 0;
      this.quoteBSelected.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.quoteASelected.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalPriceB(constructionWorkId) {
      var sum = 0;
      this.quoteBSelected.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalPrice(constructionWorkId) {
      var sum = 0;
      this.quoteASelected.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    hasBaseSpecAdjustmentB(constructionWorkId) {
      var hasItems = false;
      var res = this.quoteBSelected.options.filter(
        (n) =>
          n.construction_work_id == constructionWorkId &&
          n.is_base_spec == true &&
          n.is_adjustment == true
      );
      if (res.length > 0) {
        hasItems = true;
      }
      return hasItems;
    },
    hasBaseSpecAdjustment(constructionWorkId) {
      var hasItems = false;
      var res = this.quoteASelected.options.filter(
        (n) =>
          n.construction_work_id == constructionWorkId &&
          n.is_base_spec == true &&
          n.is_adjustment == true
      );
      if (res.length > 0) {
        hasItems = true;
      }
      return hasItems;
    },
    // spec show diff validations
    defineSpanClass(quoteB, quoteA) {
      if (quoteB.specification_swap != null && quoteA.specification_swap != null) {
        return (
          (quoteB.specification_swap.total_price * 1 +
            quoteB.specification_swap.total_delivery_install * 1) /
            this.quoteBSelected.sqft >
          (quoteA.specification_swap.total_price * 1 +
            quoteA.specification_swap.total_delivery_install * 1) /
            this.quoteASelected.sqft
        );
      } else if (quoteB.specification_swap != null && quoteA.specification_swap == null) {
        return (
          (quoteB.specification_swap.total_price * 1 +
            quoteB.specification_swap.total_delivery_install * 1) /
            this.quoteBSelected.sqft >
          quoteA.cost_per_sqft
        );
      }
    },
    defineArrowToShow(quoteB, quoteA) {
      if (quoteB.specification_swap != null && quoteA.specification_swap != null) {
        return (
          (quoteB.specification_swap.total_price * 1 +
            quoteB.specification_swap.total_delivery_install * 1) /
            this.quoteBSelected.sqft >
          (quoteA.specification_swap.total_price * 1 +
            quoteA.specification_swap.total_delivery_install * 1) /
            this.quoteASelected.sqft
        );
      } else if (quoteB.specification_swap != null && quoteA.specification_swap == null) {
        return (
          (quoteB.specification_swap.total_price * 1 +
            quoteB.specification_swap.total_delivery_install * 1) /
            this.quoteBSelected.sqft >
          quoteA.total_price / this.quoteASelected.sqft
        );
      }
    },
    diffAmountTotal(quoteB, quoteA) {
      if (quoteB.specification_swap != null && quoteA.specification_swap != null) {
        return (
          (quoteB.specification_swap.total_price * 1 +
            quoteB.specification_swap.total_delivery_install * 1) /
            this.quoteBSelected.sqft -
          ((quoteA.specification_swap.total_price * 1 +
            quoteA.specification_swap.total_delivery_install * 1) /
            this.quoteASelected.sqft) *
            1
        );
      } else if (quoteB.specification_swap != null && quoteA.specification_swap == null) {
        return (
          ((quoteB.specification_swap.total_price * 1 +
            quoteB.specification_swap.total_delivery_install * 1) /
            this.quoteBSelected.sqft) *
            1 -
          (quoteA.total_price / this.quoteASelected.sqft) * 1
        );
      }
    },
    constructionAdjustments(constructionWorkId) {
      return this.quoteASelected.options.filter(
        (n) => n.construction_work_id == constructionWorkId
      );
    },
    constructionAdjustmentsB(constructionWorkId) {
      return this.quoteBSelected.options.filter(
        (n) => n.construction_work_id == constructionWorkId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .vs__dropdown-toggle {
  background: white;
}
.increase {
  color:  rgba(59, 171, 255, 1) ;
}

.decrease {
  color: #ea5455;
}
.baseSpecVer {
  color: #b9b9c3;
}

th {
  text-align: left !important;
}
[dir] .table thead th {
  border-bottom: 1px solid #d8d6de;
  border-top: none;
}

.comment-label {
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-group-details {
  border-bottom: 1px solid rgba(31, 41, 47, 0.05);
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid  rgba(59, 171, 255, 1) ;
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color:  rgba(59, 171, 255, 1) ;
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.project-total-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #5e5873;
}

.project-total-number {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color:  rgba(59, 171, 255, 1) ;
}

.op-sc-details {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.confidential-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
  text-transform: uppercase;
}

.credit {
  color:  rgba(59, 171, 255, 1) ;
}

.section-description {
  color: #6e6b7b;
  font-size: 12px;
  font-weight: normal;
}

.base-paragraph {
  font-size: 12px;
}

.card .card-header {
  display: block !important;
}

.different {
  color: #636363;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 18px;
}

.muted {
  color: #b9b9c3;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 18px;
}
</style>
<style lang="scss" scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: unset;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem !important;
  border-top: 0px solid #ebe9f1;
}
</style>
