<template>
  <span
    :class="defClass ? 'increase' : 'decrease'"
    v-if="showSpan"
    style="font-size: 1rem; font-weight: 400; text-align: center !important"
  >
    <uil-arrow-up v-if="showArrow" />
    <uil-arrow-down v-else />{{
      Number(Math.abs(amount)).toLocaleString("en-CA", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "CAD",
      })
    }}
  </span>
</template>

<script>
import { UilRuler, UilArrowUp, UilArrowDown } from "@iconscout/vue-unicons";
export default {
  props: {
    defClass: {
      type: Boolean,
      default: false,
    },
    showSpan: {
      type: Boolean,
      default: false,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    UilRuler,
    UilArrowUp,
    UilArrowDown,
  },
};
</script>

<style lang="scss" scoped></style>
